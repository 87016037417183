import React, { useState, useContext } from "react";
import ImageContainer from "../ImageContainer/ImageContainer";
import { AppHelper } from "../../utils/Helper";
import { AppConfig } from "../../config";
import AliceCarousel from "react-alice-carousel";
import DataContext from "../../utils/Context/DataContext";
import QuantityButtonComponent from "../QuantityButtonComponent/QuantityButtonComponent";

const productresponsive = {
  0: { items: 1 },
};

function GroceryCardComponent(g_props: any) {
  const context : any = useContext(DataContext);
  const Spinner = () => <div className="loader-spinner mx-auto py-7"></div>;

  const get_mrp_data = (get_data : any) =>(
    format_amount(get_data)
  )

  function format_amount(get_data : any){
    let g_data_value = get_data?.split(".");
    if(g_data_value != undefined){
      return "₹" + g_data_value[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") + (g_data_value[1] !="00" ? ("."+g_data_value[1]): "")
    }
  }

  const getFontSize = (textLength:any, bs:any = null) => {
    const baseSize = bs != null ? bs : 21;
    if (textLength >= baseSize) {
      textLength = baseSize - 2;
    }
    if(textLength < 8) {
      if(bs != null){
        return `12px`
      } else {
        return `13px`
      }
    }
    let fontSize = baseSize - textLength
    if(g_props?.from == 'category_page' && bs == null){
      fontSize = fontSize-2;
    }
    return `${fontSize}px`
  }

  function get_variant_name(get_data : any){
    let return_data : any;
    if(get_data.hasOwnProperty("variant_specifications")){
      let spec_data = get_data.variant_specifications[0];
      let filter_data = spec_data.items.find( item => item.checked === true)
      if(filter_data != undefined){
        return_data = filter_data.name;
      }
    }
    return return_data;
  }

  return (
    <>
      <div className={g_props?.fullWidth?"col-12 p-2":"col-6 pe-0 pb-2"} key={g_props?.index}>
        <div className="prod-card border-lightgray">
          <div
            className=""
            onClick={() => {
              g_props?.open_quick_view()
              //   SetQuickView({
              //     open: true,
              //     value: g_props?.data,
              //   });
            }}
          >
            {g_props?.data.hasOwnProperty("images") ? (
              (g_props?.view_type == "grid" && g_props?.data.images.length > 1) ?(
                <div className="h-140px">
                  <AliceCarousel
                    mouseTracking
                    autoPlay={false}
                    // touchMoveDefaultEvents={false}
                    // paddingRight={20}
                    // disableDotsControls
                    responsive={productresponsive}
                    disableButtonsControls
                    controlsStrategy="alternate"
                  >
                    {g_props?.data.images.map((i_ele : any)=>(
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          context.app_data.image_base_url,
                          i_ele.url,
                          {
                            format: "webp",
                            width: 320,
                          }
                        )}
                        thumb={AppHelper.imageSrc(
                          context.app_data.image_base_url,
                          i_ele.url,
                          {
                            format: "webp",
                            width: 320,
                            quality: 10,
                          }
                        )}
                        class_name={
                          g_props?.data.available && g_props?.get_outlet_available
                            ? "w-100 prod-image "
                            : "w-100 prod-image-gray "
                        }
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                    ))}
                  </AliceCarousel>
                </div>
              ):(
                <div className="h-140px">
                  <ImageContainer
                    src={AppHelper.imageSrc(
                      context.app_data.image_base_url,
                      g_props?.data.images[0].url,
                      {
                        format: "webp",
                        width: 320,
                      }
                    )}
                    thumb={AppHelper.imageSrc(
                      context.app_data.image_base_url,
                      g_props?.data.images[0].url,
                      {
                        format: "webp",
                        width: 320,
                        quality: 10,
                      }
                    )}
                    class_name={
                      g_props?.data.available && g_props?.get_outlet_available
                        ? "w-100 prod-image "
                        : "w-100 prod-image-gray "
                    }
                    onIsVisible={() => {
                      console.log("ImageContainer On Is visible");
                    }}
                  />
                </div>
              )
            ) : (
              <div className="h-140px prod-image-gray"></div>
            )}
          </div>
          <div className="px-2 pt-2 mb-2">
            <p className="text-dark pop-font double">{g_props?.data.name}</p>
              <p className="text-gray v-small pop-font">
                {g_props.data.type == "group" && (
                  get_variant_name(g_props?.data)
                )}
              </p>
          </div>
          <div className="px-2 pb-2 mt-auto">
            <div className="row">
              <div className="col-5 p-0 w-45">
                {g_props?.data.hasOwnProperty("show_price_without_gst") ? (
                  g_props?.data.show_price_without_gst ? (
                    <>
                      {g_props?.data.price_without_gst != "" &&
                      g_props?.data.price_without_gst != null ? (
                        <p className="text-dark" style={{ fontSize: getFontSize(get_mrp_data(g_props?.data?.price_without_gst)?.length)}}>
                          {/* ₹{g_props?.data.price_without_gst} */}
                          {get_mrp_data(g_props?.data?.price_without_gst)}
                        </p>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {g_props?.data.price_with_gst != "" &&
                      g_props?.data.price_with_gst != null ? (
                        <p className="text-dark" style={{ fontSize: getFontSize(get_mrp_data(g_props?.data?.price_with_gst)?.length)}}>
                          {/* ₹{g_props?.data.price_with_gst} */}
                          {get_mrp_data(g_props?.data?.price_with_gst)}
                        </p>
                      ) : null}
                    </>
                  )
                ) : null}
                <p className="strike" style={{ fontSize: getFontSize(get_mrp_data(g_props?.data?.mrp)?.length, 18)}}>{get_mrp_data(g_props?.data?.mrp)}</p>
                {/* <p className="strike">₹ {g_props?.data.mrp}</p> */}
              </div>
              <div className="col-7 px-0 w-55">
                {g_props?.from == "vendor_page" ||
                g_props?.from == "category_page" ? (
                  g_props?.get_outlet_available ? (
                    g_props?.data.available ? (
                      g_props?.data.addbtn ? (
                        <div>
                          <div className="prod-add-btn cursor">
                            <QuantityButtonComponent
                              from_page = {"vendor"}
                              section_slug={context.app_data.selected_section.slug}
                              context_data = {context}
                              minus={() => {
                                g_props?.onminus();
                              }}
                              pluse={() => {
                                g_props?.onpluse();
                              }}
                              addcount={g_props?.data.addcount}
                              api_loading={g_props?.data.api_loading}
                            />
                            {/* <div className="d-flex">
                              {g_props?.data.api_loading ? (
                                <Spinner />
                              ) : (
                                <>
                                  //-
                                  <div
                                    className="cursor pr-1 center"
                                    onClick={() => {
                                      g_props?.onminus();
                                    }}
                                  >
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "b48a5c15d42fc79330a9658ff3779170.svg"
                                      }
                                      className="type-img"
                                    />
                                  </div>
                                  //qty
                                  <div className="w-100 center">
                                    <p className="smooth">
                                      <b>{g_props?.data.addcount}</b>
                                    </p>
                                  </div>
                                  //+
                                  <div
                                    className="cursor pl-1"
                                    onClick={() => {
                                      g_props?.onpluse();
                                    }}
                                  >
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "2a39073bf6a8f72e9d703ad4b9cdeab4.svg"
                                      }
                                      className="type-img"
                                    />
                                  </div>
                                </>
                              )}
                            </div> */}
                            {g_props?.data.customize ? (
                              <fieldset className="center-text-in-border">
                                <legend>More Options</legend>
                              </fieldset>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            className="prod-add-btn cursor"
                          >
                            {g_props?.data.api_loading ? (
                              <p className="">
                                <Spinner />
                              </p>
                            ) : (
                              <p className="" onClick={(event) => {
                                console.log("variant");
                                g_props?.onadd();
                              }}>ADD</p>
                            )}
                            {g_props?.data.customize ? (
                              <fieldset className="center-text-in-border">
                                <legend>More Options</legend>
                              </fieldset>
                            ) : null}
                          </div>
                        </div>
                      )
                    ) : (
                      <>
                        <div className="prod-add-btn">
                          {g_props?.data.next_available_at != "" &&
                          g_props?.data.next_available_at != null ? (
                            <p className="">
                              Next Available at {g_props?.data.next_available_at}
                            </p>
                          ) : (
                            <p className="fs-8px py-1">Unavailable</p>
                          )}
                        </div>
                      </>
                    )
                  ) : null
                ) : null}

                {g_props?.from == "search_page" ? (
                  g_props?.data.available ? (
                    <div>
                      <div
                        className="prod-add-btn cursor"
                        onClick={(event) => {
                          console.log("variant");
                          // const navigate = useNavigate();
                          g_props?.navigate();
                        }}
                      >
                        {g_props?.data.api_loading ? (
                          <p className="">
                            <Spinner />
                          </p>
                        ) : (
                          <p className="">ADD</p>
                        )}
                        {g_props?.data.customize ? (
                          <fieldset className="center-text-in-border">
                            <legend>More Options</legend>
                          </fieldset>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="prod-add-btn">
                        {g_props?.data.next_available_at != "" &&
                        g_props?.data.next_available_at != null ? (
                          <p className="">
                            Next Available at {g_props?.data.next_available_at}
                          </p>
                        ) : (
                          <p className="fs-8px py-1">Unavailable</p>
                        )}
                      </div>
                    </>
                  )
                ) : null}

                {/* <div className="prod-add-btn">
                ADD
                {g_props?.data.variable == true ? (
                  <fieldset className="center-text-in-border">
                    {" "}
                    <legend>{g_props?.data.no_of_variable} Options</legend>
                  </fieldset>
                ) : null}
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GroceryCardComponent;
