import React, { useState, useContext, useEffect, useRef } from "react";
import { AppHelper } from "../../utils/Helper";
import { AppConfig } from "../../config";
import DataContext from "../../utils/Context/DataContext";
import { Dialog, makeStyles, Modal } from "@material-ui/core";
import { api } from "../../utils/Api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import MapLocationPickModal from "../../pages/Common/Address/MapLocationPickModal";
import { mobileApp } from "../../utils/MobileApp";
import Cookies from "js-cookie";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      paddingBottom: "45px",
    },
    overflow: "scroll",
    width: "600px",
  },
}));

function LocationAddressList(props: any) {
  const context: any = useContext(DataContext);
  const classes = useStyles();

  const [address_list, SetAddressList] = useState([]);
  const [is_address_loading, SetIsAddressLoading] = useState(true);
  const [filtered_address_list, SetFilteredAddressList] = useState([]);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [map_lp_modal, SetMapLPModal] = useState({
    is_open: false,
    from: "",
    function: "",
    location_data: {},
    place_data: {},
  });
  const [searchTerm, SetSearchTerm] = useState("");
  const [search_places, SetSearchPlaces] = useState([]);
  const [map, setMap] = useState(null); // Needed for PlacesService
  const [location_loading, SetLocationLoading] = useState(false);
  const [continue_loading, SetContinueLoading] = useState(false);

  const Spinner = () => (
    <div className="vendor-list-loader-spinner mx-auto"></div>
  );
  const geocoder = new window.google.maps.Geocoder();
  const searchInputRef: any = useRef(null);
  const [delivery_type, SetDeliveryType] = useState([
    {
      img: "6e745f7dbe2d92dcab600140650843b6.png",
      name: "Hyper Local",
      checked: true,
      disabled: false,
    },
    {
      img: "f20407a212488dc88d921b7dacd70e5d.png",
      name: "Inshop",
      checked: false,
      disabled: true,
    },
    {
      img: "dbbbaf218748024fc60be0c19d4d6fb1.png",
      name: "Take Away",
      checked: false,
      disabled: true,
    },
    {
      img: "95baaabf0277cc4ce7630810a0485cdf.png",
      name: "Courier",
      checked: false,
      disabled: true,
    },
  ]);
  const [confirm_location, SetConfirmLocation] = useState({
    is_open: false,
    function: "",
    data: {},
  });
  const [width, SetWidth] = React.useState(innerWidth);
  // const [outlet_context_data, SetOutletContextData]: any = useOutletContext();
  const [address_menu, SetAddressMenu]: any = useState({
    is_open: false,
    d_ele: {},
  });
  const [confirm_delete, SetConfirmDelete] = useState({
    is_open: false,
    data: {},
  });

  let myModal: any;
  const navigate = useNavigate();

  function getLocation() {
    document.addEventListener("message", putLocationFromMobile);
    if (context.app_data.application.platform == "web") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, errorCallback);
      }
    } else if (
      context.app_data.application.platform == "android" ||
      context.app_data.application.platform == "ios"
    ) {
      mobileApp({
        function: "getlocationlatlng",
        app_type: "react_web",
        accuracy: "Lowest",
      });
    }
  }

  function putLocationFromMobile(message: any) {
    SetLocationLoading(false);
    SetContinueLoading(false);
    if (message.data.function == "getlocationlatlng") {
      if (message.data.status == "success") {
        if (
          message.data.lat != null &&
          message.data.lng != null &&
          message.data.lat != undefined &&
          message.data.lng != undefined
        ) {
          context.SetAppData((prevalue: any) => {
            prevalue.is_location_on = true;
            prevalue.location_data = {
              lat: message.data.lat,
              lng: message.data.lng,
            };
            return { ...prevalue };
          });
          let lat = message.data.lat;
          let lng = message.data.lng;

          if (props.from == "header") {
            SetMapLPModal({
              is_open: true,
              from: "current_location",
              function: "get_current_location",
              location_data: {
                lat: lat,
                lng: lng,
              },
              place_data: {
                formatted_address: "",
              },
            });
          }

          if (props.from != "header") {
            SetMapLPModal({
              is_open: true,
              from: "get_location_to_save",
              function: "get_current_location",
              location_data: {
                lat: lat,
                lng: lng,
              },
              place_data: {
                formatted_address: "",
              },
            });
          }
        } else {
          // map.setCenter({ lat: "11.3410", lng: "77.7172" });
          context.SetAppData((prevalue: any) => {
            prevalue.is_location_on = false;
            prevalue.location_data = {};
            return { ...prevalue };
          });
          alert("Enable Location");
        }
      } else {
        context.SetAppData((prevalue: any) => {
          prevalue.is_location_on = false;
          prevalue.location_data = {};
          return { ...prevalue };
        });
        alert("Enable Location");
      }
    }

    document.removeEventListener("message", () => {});
  }

  function showPosition(
    position: any,
    placeLocationMarker: any = true,
    placeMarker: any = false
  ) {
    context.SetAppData((prevalue: any) => {
      prevalue.is_location_on = true;
      prevalue.location_data = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      return { ...prevalue };
    });
    SetLocationLoading(false);
    SetContinueLoading(false);
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    if (props.from == "header") {
      SetMapLPModal({
        is_open: true,
        from: "current_location",
        function: "get_current_location",
        location_data: {
          lat: lat,
          lng: lng,
        },
        place_data: {
          formatted_address: "",
        },
      });
    }

    if (props.from != "header") {
      SetMapLPModal({
        is_open: true,
        from: "get_location_to_save",
        function: "get_current_location",
        location_data: {
          lat: lat,
          lng: lng,
        },
        place_data: {
          formatted_address: "",
        },
      });
    }
  }

  function errorCallback(error: any) {
    context.SetAppData((prevalue: any) => {
      prevalue.is_location_on = false;
      prevalue.location_data = {};
      return { ...prevalue };
    });
    alert("Enable Location");
    SetLocationLoading(false);
    SetContinueLoading(false);
    console.log("error :", error);
    console.log("props :", props);
  }

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    // let myModal1: any = document.getElementById("AdderList");
    // myModal1.addEventListener("hidden.bs.modal", function (event: any) {
    //   console.log("event :", event);
    //   props.close(close_data);
    // });
    let send_data = {
      key: "delivery_address",
    };

    // if (context.is_data_available(send_data)) {
    //   SetAddressList(context.app_data.delivery_address);
    //   SetFilteredAddressList(context.app_data.delivery_address);
    //   SetIsAddressLoading(false);
    // } else {
    //   get_delivery_address();
    // }

    get_delivery_address();

    // if (width < 720) {
    //   SetOutletContextData((prevValue: any) => {
    //     prevValue.have_back = props.have_back;
    //     prevValue.have_back_value = false;
    //     prevValue.back_value = "";
    //     prevValue.title = "Cart";
    //     prevValue.have_footer = true;
    //     prevValue.link = {
    //       rel: "canonical",
    //       href: "http://mysite.com/example",
    //     };
    //     return { ...prevValue };
    //   });
    // } else {
    //   SetOutletContextData((prevValue: any) => {
    //     prevValue.have_back = props.have_back;
    //     prevValue.have_back_value = false;
    //     prevValue.back_value = "";
    //     prevValue.title = "Cart";
    //     prevValue.have_footer = true;
    //     prevValue.link = {
    //       rel: "canonical",
    //       href: "http://mysite.com/example",
    //     };
    //     return { ...prevValue };
    //   });
    // }
  }, []);

  useEffect(() => {
    // Load the Google PlacesService once the map is available
    if (window.google && searchInputRef.current && !map) {
      const dummyMapDiv = document.createElement("div");
      const tempMap: any = new window.google.maps.Map(dummyMapDiv); // Create a dummy map to initialize PlacesService
      setMap(tempMap);
    }
  }, [map]);

  useEffect(() => {
    if (props.open) {
      console.log("Model LocationAddressList On props.open :", props);

      // myModal = new bootstrap.Modal(document.getElementById("AdderList"), {});
      // myModal.show();
      let send_data = {
        key: "delivery_address",
      };

      if (context.is_data_available(send_data)) {
        SetAddressList(context.app_data.delivery_address);
        SetFilteredAddressList(context.app_data.delivery_address);
        SetIsAddressLoading(false);
      } else {
        get_delivery_address();
      }
    }
  }, [props.open]);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      const results = address_list.filter((list: any) =>
        list.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      SetFilteredAddressList(results);
    } else {
      if (searchTerm.length == 0) {
        SetFilteredAddressList([]);
        SetSearchPlaces([]);
      }
    }
  }, [searchTerm]);

  function GetAddressByLocation(get_data: any) {
    let return_data = "";
    var latlng = new google.maps.LatLng(
      get_data.location.lat,
      get_data.location.lng
    );

    geocoder.geocode({ location: latlng }, (results: any, status) => {
      console.log("GetAddressByLocation status :", status);
      console.log("GetAddressByLocation results :", results);
      console.log(
        "GetAddressByLocation true false :",
        status === "OK" && results[0]
      );

      if (status === "OK") {
        // setAddress(results[0].formatted_address);
        return_data = results[0].formatted_address;
      } else {
        // setAddress("No address found");
        return_data = "";
      }
    });
    return return_data;
  }

  const handleSearchInputChange = () => {
    const query = searchInputRef.current.value;

    if (query && map) {
      const service = new window.google.maps.places.PlacesService(map);

      const request = {
        query: query,
        // fields: ["name", "formatted_address"],
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      };

      service.textSearch(request, (results: any, status: any) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          SetSearchPlaces(results);
        } else {
          SetSearchPlaces([]);
        }
      });
    } else {
      SetSearchPlaces([]);
    }
  };

  async function get_delivery_address() {
    let pass_data: any = {
      get: {},
    };
    console.log("get_delivery_address pass data :", pass_data);
    let response = await api("/delivery_address", pass_data);
    console.log("get_delivery_address response :", response);
    if (response.status_code === 200) {
      if (response.response.hasOwnProperty("delivery_address")) {
        let address_data = response.response.delivery_address;
        address_data.map((ele: any) => {
          ele.selected_address = false;
        });
        let selected_address_id: any = {};
        let selected_address: any = [];
        console.log(
          "context.app_data.selected_section :",
          context.app_data.selected_section
        );
        let save_new_array: any = [];
        context.SetAppData((prevalue: any) => {
          if (Object.keys(prevalue.selected_section).length > 0) {
            let shipment_data = prevalue.shipment_details;
            console.log("shipment_data on check :", shipment_data);
            shipment_data.sections.map((ele: any) => {
              if (ele.section_slug == prevalue.selected_section.slug) {
                if (
                  ele.delivery_address != "" &&
                  ele.delivery_address != null &&
                  ele.delivery_address != undefined
                ) {
                  selected_address_id = ele.delivery_address;
                }
              }
            });
          }
          save_new_array = moveObjectToStartById(
            address_data,
            selected_address_id
          );

          // let index_value = address_data.findIndex(
          //   (ele: any) => ele.id == selected_address_id
          // );
          // selected_address = address_data.find(
          //   (ele: any) => ele.id == selected_address_id
          // );
          // console.log("index_value :", index_value);
          // if (index_value != -1) {
          //   // // if (address_data[0].id != selected_address_id) {
          //   //   address_data.slice(index_value);
          //   //   selected_address.selected_address = true;
          //   //   address_data.splice(0, 0, selected_address);
          //   // // }
          // }
          return { ...prevalue };
        });
        console.log("address_data :", address_data);
        console.log("save_new_array :", save_new_array);
        // SetAddressList(address_data);
        // SetFilteredAddressList(address_data);
        SetAddressList(save_new_array);
        SetFilteredAddressList(save_new_array);
      }
      SetIsAddressLoading(false);
    }
  }

  const moveObjectToStartById = (arr: any, id: any) => {
    // Find the index of the object with the matching id
    const fromIndex = arr.findIndex((item: any) => item.id === id);

    if (fromIndex === -1) {
      return arr; // If the id is not found, return the original array
    }

    // Remove the object from its current position
    const [item] = arr.splice(fromIndex, 1);

    // Add the object to the start of the array
    arr.unshift(item);
    arr.map((ele: any, index: any) => {
      if (index == 0) {
        ele.checked = true;
      } else {
        ele.checked = false;
      }
    });
    return arr;
  };

  const AddressListSkeleton = (als_props: any) => {
    return (
      <>
        <div className="mt-3 mb-2 sub-head-text">Saved address</div>
        <div className="mb-5">
          {[1, 2, 3, 4, 5, 6].map(() => (
            <div className="card p-3 radius-10 mb-2">
              <div className="pb-2 px-0 d-flex align-items-center w-100">
                <div className="d-flex align-items-center w-100 ps-0">
                  <Skeleton height="8px" width="100%" />
                  <div className="fs-12 ps-2 pop-font text-dark">
                    <Skeleton height="8px" width="100%" />
                  </div>
                </div>
              </div>
              <div className="fs-11px text-gray pop-regular let_space_03 border-bottom pb-2">
                <Skeleton height="8px" width="100%" />
                <Skeleton height="8px" width="75%" />
              </div>
              <div className="pt-2 d-flex align-items-center w-100 ">
                <div className="w-50  d-flex align-items-center">
                  <Skeleton height="8px" width="100%" />
                </div>
                <div className="w-50 end">
                  <Skeleton height="8px" width="100%" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const AddressList = (al_props: any) => {
    let delivery_address_list = address_list;
    let display_address_list: any = [];

    delivery_address_list.map((ele: any) => {
      if (
        ele.latitude != "" &&
        ele.latitude != null &&
        ele.latitude != undefined &&
        ele.longitude != "" &&
        ele.longitude != null &&
        ele.longitude != undefined
      ) {
        display_address_list.push(ele);
      }
    });
    const [list_show_all, SetListShowAll] = useState(false);
    const AddressListToShow = list_show_all
      ? display_address_list
      : display_address_list.slice(0, 3);

    return (
      <>
        {searchTerm.length < 3 ? (
          AddressListToShow.length > 0 ? (
            <>
              <div className="mt-3 mb-2 sub-head-text">Saved address</div>
              <div className="mb-5">
                {AddressListToShow.map((d_ele: any) => (
                  <div
                    className="card p-3 radius-10 mb-2"
                    // data-bs-dismiss="modal"
                  >
                    <div className="pb-2 px-0 d-flex align-items-center w-100">
                      <div className="d-flex align-items-center w-100 ps-0">
                        {d_ele.type == 1 ? (
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "37465a03db5aa15f878902d80b06a7bf.svg"
                            }
                            className="w-14px"
                          />
                        ) : d_ele.type == 2 ? (
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "7949c079a6ce14a872007e522a5551b8.svg"
                            }
                            className="w-14px"
                          />
                        ) : (
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "befc4af950fe75f6637eb8f8f8ebdd84.svg"
                            }
                            className="w-14px"
                          />
                        )}
                        {/* <img
                          src={
                            AppConfig.CDN_Media_url + "37465a03db5aa15f878902d80b06a7bf.svg"
                          }
                          className="w-14px"
                        /> */}
                        {d_ele.type == 0 || d_ele.type == null ? (
                          <div className="fs-12 ps-2 loc-add">
                            {d_ele.other_type}
                          </div>
                        ) : (
                          <div className="fs-12 ps-2 loc-add">
                            {d_ele.type_value}
                          </div>
                        )}
                        {/* <div className="fs-12 ps-2 pop-font text-dark">HOME</div> */}
                        {d_ele.checked && (
                          <div className="fs-10px py-1 loc-add bg-green-div px-2 ms-2">
                            Selected Address
                          </div>
                        )}
                        <div
                          className="ms-auto"
                          onClick={() => {
                            SetAddressMenu({
                              is_open: true,
                              d_ele: d_ele,
                            });
                          }}
                        >
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "9edfdd6a8403e3e52f981e28c5fe5c43.svg"
                            }
                            className="w-16px"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        al_props.onClick(d_ele);
                      }}
                    >
                      <div className="fs-11px text-gray pop-regular let_space_03 border-bottom pb-2">
                        {d_ele.map_address}
                      </div>
                      {d_ele.name != "" || d_ele.mobile != "" ? (
                        <div className="pt-2 d-flex align-items-center w-100 ">
                          {d_ele.name != "" && (
                            <div className="w-50  d-flex align-items-center">
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "1acf785b7860b8ff62147bd01e501d51.svg"
                                }
                                className="w-13"
                              />
                              <div className="fs-12 ps-2 text-black pop-regular">
                                {d_ele.name}
                              </div>
                            </div>
                          )}
                          {d_ele.mobile != "" && (
                            <div className="w-50 end">
                              <div className="fs-12 pe-2 text-black pop-regular">
                                {d_ele.mobile}
                              </div>
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "b07293a39f4df3c962204eba4d8cb1b3.svg"
                                }
                                className="w-13"
                              />
                            </div>
                          )}
                        </div>
                      ) : null}

                    </div>
                  </div>
                ))}

                <div
                  className="fw-bold p-2 loc-add text-primary cursor"
                  onClick={() => {
                    SetListShowAll((prevalue) => {
                      prevalue = !prevalue;
                      return prevalue;
                    });
                  }}
                >
                  {list_show_all ? "View less" : "View all"}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="text-center px-3 py-5 mb-5">
                <img
                  src={
                    AppConfig.CDN_Image_url + "f88ece4d94e7d63fea2fce40c031d180.png"
                  }
                  className="w-100px h-160px"
                />
                <div className="address-sub-text let_space_03 mt-2">
                  No address found, add address
                </div>
              </div>
            </>
          )
        ) : (
          <>
            {filtered_address_list.length > 0 ? (
              <>
                <div className="mt-3 mb-2 sub-head-text">Saved address</div>
                <div className="mb-5">
                  {filtered_address_list.map((d_ele: any) => (
                    <div
                      className="card p-3 radius-10 mb-2"
                      // data-bs-dismiss="modal"
                    >
                      <div className="pb-2 px-0 d-flex align-items-center w-100">
                        <div className="d-flex align-items-center w-100 ps-0">
                          {d_ele.type == 1 ? (
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "37465a03db5aa15f878902d80b06a7bf.svg"
                              }
                              className="w-14px"
                            />
                          ) : d_ele.type == 2 ? (
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "7949c079a6ce14a872007e522a5551b8.svg"
                              }
                              className="w-14px"
                            />
                          ) : (
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "befc4af950fe75f6637eb8f8f8ebdd84.svg"
                              }
                              className="w-14px"
                            />
                          )}
                          {/* <img
                            src={
                              AppConfig.CDN_Media_url + "37465a03db5aa15f878902d80b06a7bf.svg"
                            }
                            className="w-14px"
                          /> */}
                          {d_ele.type == 0 || d_ele.type == null ? (
                            <div className="fs-12 ps-2 loc-add">
                              {d_ele.other_type}
                            </div>
                          ) : (
                            <div className="fs-12 ps-2 loc-add">
                              {d_ele.type_value}
                            </div>
                          )}
                          {/* <div className="fs-12 ps-2 pop-font text-dark">HOME</div> */}
                          {d_ele.selected_address && (
                            <div className="fs-12 ps-2 loc-add bg-green-div m-2">
                              Selected Address
                            </div>
                          )}
                          <div
                            className="ms-auto"
                            onClick={() => {
                              SetAddressMenu({
                                is_open: true,
                                d_ele: d_ele,
                              });
                            }}
                          >
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "9edfdd6a8403e3e52f981e28c5fe5c43.svg"
                              }
                              className="w-16px"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          al_props.onClick(d_ele);
                        }}
                      >
                        <div className="fs-11px text-gray pop-regular let_space_03 border-bottom pb-2">
                          {d_ele.map_address}
                        </div>
                        <div className="pt-2 d-flex align-items-center w-100 ">
                          <div className="w-50  d-flex align-items-center">
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "1acf785b7860b8ff62147bd01e501d51.svg"
                              }
                              className="w-13"
                            />
                            <div className="fs-12 ps-2 text-black pop-regular">
                              {d_ele.name}
                            </div>
                          </div>
                          <div className="w-50 end">
                            <div className="fs-12 pe-2 text-black pop-regular">
                              {d_ele.mobile}
                            </div>
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "b07293a39f4df3c962204eba4d8cb1b3.svg"
                              }
                              className="w-13"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ): null}
          </>
        )}
      </>
    );
  };

  const SearchResultList = (srl_props: any) => {
    return (
      <div className="mt-3 mb-5">
        <div className="sub-head-text">Search result</div>
        <div className="mt-2 mb-1 card shadow radius-10 px-3 py-2">
          {search_places.length > 0 ? (
            search_places.map((place: any, index: any) => (
              <div
                className="py-3 recent_search border-bottom"
                key={index}
                onClick={() => {
                  console.log("place :", place);
                  let lat = place.geometry.location.lat();
                  let lng = place.geometry.location.lng();
                  console.log("lat :", lat);
                  console.log("lng :", lng);
                  SetMapLPModal({
                    is_open: true,
                    from: "search",
                    function: "no_function",
                    location_data: {
                      lat: lat,
                      lng: lng,
                    },
                    place_data: place,
                  });
                }}
              >
                <div className="d-flex align-items-center ">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
                    }
                    className="w-14px"
                  />
                  <div className="ps-2 loc-add">{place.name}</div>
                </div>
                <div className="pt-1 sub-head-text fs-12px">
                  {place.formatted_address}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center px-3 py-5 mb-5">
                <img
                  src={
                    AppConfig.CDN_Image_url + "f88ece4d94e7d63fea2fce40c031d180.png"
                  }
                  className="w-100px h-160px"
                />
                <div className="address-sub-text let_space_03 mt-2">
                  No search results
                </div>
              </div>
          )}
        </div>
      </div>
    );
  };

  const SearchResultListSkeleton = (srl_props: any) => {
    return (
      <div className="mt-2 mb-5">
        <div className="sub-head-text">Search result</div>
        {[1, 2, 3, 4].map((place: any, index: any) => (
          <div
            className="mt-2 mb-1 card shadow radius-10 px-3 py-2"
            key={index}
          >
            <div className="py-2 recent_search border-bottom">
              <div className="d-flex align-items-center ">
                {/* <img
                  src={
                    AppConfig.CDN_Media_url +
                    "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
                  }
                  className="w-14px"
                />
                <div className="ps-2 loc-add">{place.name}</div> */}
                <Skeleton height="15px" width="20%" />
                <Skeleton height="15px" width="80%" />
              </div>
              <div className="pt-1 sub-head-text fs-12px">
                <Skeleton height="12px" width="100%" />
                <Skeleton height="12px" width="50%" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  async function set_shipment(get_data: any) {
    console.log(
      "set shipment context.app_data.selected_section :",
      context.app_data.selected_section
    );

    let pass_data: any = {
      post: {},
    };
    let selected_delivery_type: any = [];
    delivery_type?.map((ele) => {
      if (ele.checked) {
        selected_delivery_type.push(ele);
      }
    });
    selected_delivery_type?.map((ele: any) => {
      if (ele.value == "in_shop") {
        if (ele.checked) {
          pass_data.post.inshop = 1;
        } else {
          pass_data.post.inshop = 0;
        }
      }
      if (ele.value == "hyper_local") {
        if (ele.checked) {
          pass_data.post.hyperlocal = 1;
        } else {
          pass_data.post.hyperlocal = 0;
        }
      }
      if (ele.value == "take_away") {
        if (ele.checked) {
          pass_data.post.takeaway = 1;
        } else {
          pass_data.post.takeaway = 0;
        }
      }
      if (ele.value == "courier") {
        if (ele.checked) {
          pass_data.post.courier = 1;
        } else {
          pass_data.post.courier = 0;
        }
      }
    });

    if (get_data.from == "location_picker") {
      pass_data.post.lat = get_data.selected_delivery_address.latitude;
      pass_data.post.lng = get_data.selected_delivery_address.longitude;
      pass_data.post.map_address =
        get_data.selected_delivery_address.map_address;
      pass_data.post.short_map_address =
        get_data.selected_delivery_address.short_map_address;
    }
    if (get_data.from == "select_address") {
      pass_data.post.delivery_address = get_data.selected_delivery_address.id;
    }
    if (Object.keys(context.app_data.selected_section).length > 0) {
      pass_data.post.section_slug = context.app_data.selected_section.slug;
    }
    console.log("res set_shipment pass_data :", pass_data);
    let response = await api("/set_shipment", pass_data);
    console.log("res set_shipment :", response);
    if (response.status_code == 200) {
      context.SetAppData((prevalue: any) => {
        prevalue.shipment_details = response.response.shipment_details;
        if (get_data.from == "location_picker") {
          prevalue.lat_lang = {
            lat: get_data.picked_lat_long.lat,
            lang: get_data.picked_lat_long.lng,
          };
        }
        if (get_data.from == "select_address") {
          prevalue.lat_lang = {
            lat: get_data.selected_delivery_address.latitude,
            lang: get_data.selected_delivery_address.longitude,
          };
        }
        return { ...prevalue };
      });
      Cookies.set(
        "shipment_data",
        JSON.stringify(response.response.shipment_details)
      );

      if (get_data.from == "location_picker") {
        Cookies.set(
          "location",
          JSON.stringify({
            lat: get_data.picked_lat_long.lat,
            lang: get_data.picked_lat_long.lng,
          })
        );
      }
      if (get_data.from == "select_address") {
        Cookies.set(
          "location",
          JSON.stringify({
            lat: get_data.selected_delivery_address.latitude,
            lang: get_data.selected_delivery_address.longitude,
          })
        );
      }
      props.onClose(close_data);
    }
  }

  async function delete_saved_address(get_data: any) {
    let pass_data: any = {
      post: {
        id: get_data.id,
      },
    };
    console.log("remove_delivery_address pass_data :", pass_data);
    let response = await api("/remove_delivery_address", pass_data);
    console.log("res remove_delivery_address :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("delivery_address")) {
        SetAddressList(response.response.delivery_address);
        SetFilteredAddressList(response.response.delivery_address);
      }
    }
    SetConfirmDelete({
      is_open: false,
      data: {},
    });
  }

  function show_confirm_modal(get_data: any) {
    let orgin = { lat: get_data.orgin_data.lat, lng: get_data.orgin_data.lng };
    let destination = {
      lat: get_data.destination_data.lat,
      lng: get_data.destination_data.lng,
    };
    console.log("getDistance :", getDistance(orgin, destination));
    if (getDistance(orgin, destination) > 50) {
      if (props.from == "header") {
        SetConfirmLocation({
          is_open: true,
          function: "call_shipment",
          data: get_data.address_data,
        });
      }
      if (props.from != "header") {
        SetConfirmLocation({
          is_open: true,
          function: "save_location",
          data: get_data.address_data,
        });
      }
    } else {
      if (props.from == "header") {
        let send_data = {
          from: "select_address",
          selected_delivery_type: [],
          selected_delivery_address: get_data,
        };
        console.log("on save send_data :", send_data);
        set_shipment(send_data);
      }

      if (props.from != "header") {
        let send_data = {
          action: "save",
          from: props.from,
          value: {
            selected_address: confirm_location.data,
          },
        };
        console.log("on save send_data :", send_data);
        props.onClose(send_data);
      }
    }
  }

  function getDistance(p1: any, p2: any) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  }

  function rad(x: any) {
    return (x * Math.PI) / 180;
  }

  return (
    <>
      {/* <div
        className="modal fade"
        id="AdderList"
        tabIndex={-1}
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      > */}
      {/* <div className="modal-dialog modal-fullscreen-sm-down"> */}
      {/* <div className="modal-content"> */}
      <div className="scrolling-card overflowY-max card p-0">
        <div className="">
          {/* header */}
          <div className="header header-fixed-top header-badge-padd border-bottom-1 d-flex align-items-center custom-container px-2">
            {/* <div
            className="w-10per text-left cursor-no-animate p-2"
            onClick={() => {
              props.onClose();
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "3760dcf7e8daefc2196a2b9f357fbec1.svg"
              }
              className="type-img"
            />
          </div>
          <div className="w-80per center p-2">
            <h5 className="mb-0 text-black ">Delivery</h5>
          </div>
          <div className="w-10per"></div> */}
            <div
              className="ps-1 pe-2 cursor"
              onClick={() => {
                if (props.from == "header") {
                  props.onClose();
                }
                if (props.from != "header") {
                  let send_data = {
                    action: "close",
                    from: props.from,
                  };
                  props.onClose(send_data);
                }
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "30549c56761b976b23418471a0f0037a.svg"
                }
                className="w-14px"
              />
            </div>
            <div className="ps-1 pe-0 w-100">
              <div className="loc-head">Select a location</div>
            </div>
          </div>
          {/* header */}
          {/* <div className="modal-header shadow border-none">
              <div className="ps-1 pe-2 cursor" data-bs-dismiss="modal">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "30549c56761b976b23418471a0f0037a.svg"
                  }
                  className="w-14px"
                />
              </div>
              <div className="ps-1 pe-0 w-100">
                <div className="loc-head">Select a location</div>
              </div>
            </div> */}
          {/* <div className="modal-body bg-body-color "> */}

          <div className="custom-container mt-7 p-2 bg-body-color">
            {/* search */}
            <div className="address-search-bar d-flex align-items-center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "4075afa7aafecff54a5220213ad396f0.svg"
                }
                className="w-20px"
              />
              <div className="ps-2 ">
                <input
                  type="text"
                  // id={"search-input"}
                  ref={searchInputRef}
                  className=" custom-input-text"
                  placeholder="Enter location manually"
                  value={searchTerm}
                  onChange={(e: any) => {
                    if (props.from != "cart") {
                      handleSearchInputChange();
                    }
                    console.log("e :", e);
                    SetSearchTerm(e.target.value);
                  }}
                />
              </div>
              <div className="ms-auto cursor ps-2">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "d6da0c9c3517352622470b635f8a8d38.svg"
                  }
                  className="w-20px"
                  onClick={() => {
                    // handleSearchInputChange()
                    SetSearchTerm("");
                  }}
                />
              </div>
            </div>
            {/* use my current location / add address*/}
            <div className="mt-3 card shadow p-3 radius-10">
              {props.from != "cart" ? (
                <div
                  className="d-flex align-items-center pb-3 border-bottom"
                  onClick={() => {
                    SetLocationLoading((prevalue) => {
                      if (!prevalue) {
                        getLocation();
                        SetContinueLoading(true);
                        prevalue = true;
                      } else {
                        console.log("location loading");
                      }
                      return prevalue;
                    });
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
                    }
                    className="w-16px"
                  />
                  <div className="ps-3 loc-add">Use my current location</div>
                  <div className="ms-auto pop-font fs-11px text-primary">
                    {location_loading && <Spinner />}
                  </div>
                </div>
              ) : null}

              {/* add address */}
              <div
                className={
                  props.from != "cart"
                    ? "d-flex align-items-center pt-3 "
                    : "d-flex align-items-center"
                }
                onClick={() => {
                  // navigate("/AddAddressNew");
                  // SetMapLPModal({
                  //   is_open: true,
                  //   from:"add_address",
                  //   function : "get_current_location",
                  //   location_data: {
                  //     lat : 11.341,
                  //     lng : 77.7172,
                  //   },
                  //   place_data : {}
                  // });
                  let send_data = {
                    location: {
                      lat: 11.341,
                      lng: 77.7172,
                    },
                  };
                  console.log(
                    "GetAddressByLocation :",
                    GetAddressByLocation(send_data)
                  );
                  // if(GetAddressByLocation(send_data) != ""){

                  SetMapLPModal({
                    is_open: true,
                    from: "add_address",
                    function: "get_current_location",
                    location_data: {
                      lat: 11.341,
                      lng: 77.7172,
                    },
                    place_data: {
                      formatted_address: GetAddressByLocation(send_data),
                    },
                  });
                  // }
                }}
              >
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "ed0c77a7912938b1203b742ed5f5ed2e.svg"
                  }
                  className="w-16px"
                />
                <div className="ps-3 loc-add">Add new address</div>
              </div>
            </div>
            {/* Saved addres list */}
            {is_address_loading ? (
              <AddressListSkeleton />
            ) : (
              <AddressList
                onClick={(data: any) => {
                  console.log("on address click data :", data);
                  // if (props.from == "header") {
                  //   SetConfirmLocation({
                  //     is_open: true,
                  //     function: "call_shipment",
                  //     data: data,
                  //   });
                  // }

                  // if (props.from != "header") {
                  //   SetConfirmLocation({
                  //     is_open: true,
                  //     function: "save_location",
                  //     data: data,
                  //   });
                  // }
                  //
                  if (props.from != "cart") {
                    if (context.app_data.is_location_on) {
                      let send_data = {
                        orgin_data: {
                          lat: context.app_data.location_data.lat,
                          lng: context.app_data.location_data.lng,
                        },
                        destination_data: {
                          lat: data.latitude,
                          lng: data.longitude,
                        },
                        address_data: data,
                      };
                      show_confirm_modal(send_data);
                    } else {
                      if (props.from == "header") {
                        let send_data = {
                          from: "select_address",
                          selected_delivery_type: [],
                          selected_delivery_address: data,
                        };
                        console.log("on save send_data :", send_data);
                        set_shipment(send_data);
                      }

                      if (props.from != "header") {
                        let send_data = {
                          action: "save",
                          from: props.from,
                          value: {
                            selected_address: data,
                          },
                        };
                        console.log("on save send_data :", send_data);
                        props.onClose(send_data);
                      }
                    }
                  } else {
                    let send_data = {
                      action: "save",
                      from: props.from,
                      value: {
                        selected_address: data,
                      },
                      props_value: props.props_data,
                    };
                    console.log("on save send_data :", send_data);
                    props.onClose(send_data);
                  }
                }}
              />
            )}
            {/* recent search */}
            {/* <div className="mt-3  sub-head-text">Recent search</div>
                <div className="mt-2 mb-5 card shadow radius-10 px-3 py-2">
                  <div
                    className="py-2 recent_search border-bottom"
                    onClick={() => {
                      SetMapLPModal({
                        is_open: true,
                      });
                    }}
                  >
                    <div className="d-flex align-items-center ">
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
                        }
                        className="w-14px"
                      />
                      <div className="ps-2 loc-add">Erode Bus Stand</div>
                    </div>
                    <div className="pt-1 sub-head-text fs-12px">
                      Erode main, Tamilnadu, India
                    </div>
                  </div>

                  <div
                    className="py-2 recent_search border-bottom"
                    onClick={() => {
                      SetMapLPModal({
                        is_open: true,
                      });
                    }}
                  >
                    <div className="d-flex align-items-center ">
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
                        }
                        className="w-14px"
                      />
                      <div className="ps-2 loc-add">Erode Bus Stand</div>
                    </div>
                    <div className="pt-1 sub-head-text fs-12px">
                      Erode main, Tamilnadu, India
                    </div>
                  </div>
                </div> */}
            {/* search result */}
            {props.from != "cart" ? (
              searchInputRef.current?.value != "" ||
              searchInputRef.current?.value.length > 3 ||
              search_places.length > 0 ? (
                <SearchResultList />
              ) : null
            ) : null}
          </div>
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
      {map_lp_modal.is_open && (
        <Modal open={map_lp_modal.is_open}>
          <MapLocationPickModal
            // open={map_lp_modal.is_open}
            from={map_lp_modal.from}
            location_list_props_from={props.from}
            location_data={map_lp_modal.location_data}
            place_data={map_lp_modal.place_data}
            onClose={(data: any) => {
              console.log("MapLocationPickerModal on close :", data);
              if (data.action == "save") {
                props.onClose(data);
                // var myModalEl = document.getElementById("AdderList");
                // var modal_close = bootstrap.Modal.getInstance(myModalEl);
                // modal_close.hide();
                // var myModalEl = new bootstrap.Modal(
                //   document.getElementById("AdderList"),
                //   {}
                // );
                // // var modal_close = bootstrap.Modal.getInstance(myModalEl);
                // myModalEl.hide();
              }
              if (data.action == "address_save") {
                SetAddressList(data.address_value);
                SetFilteredAddressList(data.address_value);
              }
              if (data.action == "get_location_to_save") {
                let send_data: any = {
                  value: {
                    selected_address: {},
                    picked_lat_long: {
                      lat: data.lat,
                      long: data.lng,
                    },
                    picked_address: data.full_address,
                    short_map_address: data.short_address,
                  },
                  from: props.from,
                  action: "save",
                };
                props.onClose(send_data);
              }
              if (data.action == "search") {
                let send_data: any = {
                  value: {
                    selected_address: {},
                    picked_lat_long: {
                      lat: data.lat,
                      long: data.lng,
                    },
                    picked_address: data.full_address,
                    short_map_address: data.short_address,
                  },
                  from: props.from,
                  action: "save",
                };
                props.onClose(send_data);
              }
              SetMapLPModal({
                is_open: false,
                from: "",
                function: "",
                location_data: {},
                place_data: {},
              });
            }}
          />
        </Modal>
      )}
      {/* </div> */}

      {/* <Dialog
        open={address_menu.is_open}
        classes={{ container: classes.root, paper: classes.paper }}
        onClose={() => {
          SetAddressMenu({
            is_open: false,
            d_ele: {},
          });
        }}
      > */}
      <SwipeableBottomSheet
        open={address_menu.is_open}
        // overlay={true}
        // overflowHeight={0}
        onChange={() => {
          SetAddressMenu({
            is_open: false,
            d_ele: {},
          });
        }}
      >
        <div className="px-2 py-3 bg-fff sticky-top mb-3">
          <div className="sub-head-text mb-2 text-center">
            {address_menu.d_ele.type == 0 || address_menu.d_ele.type == null ? (
              <span className="text-black-color fw-bold">
                {address_menu.d_ele.other_type} |
              </span>
            ) : (
              <span className="text-black-color fw-bold">
                {address_menu.d_ele.type_value} |
              </span>
            )}
            <span className="text-gray fw-400 ps-1">
              {address_menu.d_ele.map_address}
            </span>
          </div>
          <div className="text-center px-3 py-2 ">
            {/* <div
              className="sub-head-text cursor text-black-color py-3 border-bottom center "
              onClick={() => {
                // SetAddressMenu({
                //   is_open: false,
                //   d_ele: {},
                // });
                SetAddressMenu((prevalue: any) => {
                  console.log("SetAddressMenu prevalue :", prevalue);
                  console.log("SetAddressMenu prevalue :", prevalue.is_open);
                  console.log("SetAddressMenu prevalue :", prevalue.d_ele);

                  SetMapLPModal({
                    is_open: true,
                    from: "edit_address",
                    function: "",
                    location_data: {
                      lat: prevalue.d_ele.latitude,
                      lng: prevalue.d_ele.longitude,
                    },
                    place_data: prevalue.d_ele,
                  });
                  prevalue.is_open = false;
                  prevalue.d_ele = {};
                  return { ...prevalue };
                });
              }}
            >
              <div className="w-20px center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "21d3a9e5cedf31f69753210bf5015802.svg"
                  }
                  className="me-3 w-16px"
                />
              </div>
              Edit
            </div> */}
            {/* <div className="sub-head-text cursor text-black-color py-3 border-bottom center ">
              <div className="w-20px center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "3be1da96e52838af30747800bd3f8bc3.svg"
                  }
                  className="me-2 w-16px"
                />
              </div>
              Share
            </div> */}
            <div
              className="sub-head-text cursor text-black-color py-3 center "
              onClick={() => {
                SetAddressMenu((prevalue: any) => {
                  prevalue.is_open = false;
                  SetConfirmDelete({
                    is_open: true,
                    data: prevalue.d_ele,
                  });
                  return { ...prevalue };
                });
              }}
            >
              <div className="w-20px center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "43f75ee021f7f25970f2d20f9d504ef0.svg"
                  }
                  className="me-2 w-16px"
                />
              </div>
              Delete
            </div>
          </div>
        </div>
      </SwipeableBottomSheet>
      {/* </Dialog> */}

      {/* confirm_location */}
      <SwipeableBottomSheet
        open={confirm_location.is_open}
        // overlay={true}
        // overflowHeight={0}
        onChange={() => {
          SetConfirmLocation({
            is_open: false,
            function: "",
            data: {},
          });
        }}
      >
        <div className="px-2 py-3 text-center bg-fff sticky-top custom-swipe-shadow">
          <div className="center pt-2 ">
            <img
              src={
                AppConfig.CDN_Media_url + "fa87db880efda3e5cc5c5d11e350e660.svg"
              }
              className="w-50px"
            />
            {/* <img src={AppConfig.CDN_Media_url + "847c4685f77fef520653d7d275d085ed.svg"} className="w-30px" /> */}
          </div>
          <div className="loc-head mt-2">
            Are you sure of the selected location?
          </div>
          <div className="py-2 sub-head-text">
            Your selected location seems to be a little far off from the device
            location
          </div>

          <button
            className="primary-button mt-3"
            onClick={() => {
              SetConfirmLocation({
                is_open: false,
                function: "",
                data: {},
              });
            }}
          >
            No, select another location
          </button>

          <button
            className="primary-button bg-fff w-100 text-primary mt-2"
            onClick={() => {
              if (confirm_location.function == "call_shipment") {
                let send_data = {
                  from: "select_address",
                  selected_delivery_type: [],
                  selected_delivery_address: confirm_location.data,
                };
                console.log("on save send_data :", send_data);
                set_shipment(send_data);
              }

              if (confirm_location.function == "save_location") {
                let send_data = {
                  // from: "select_address",
                  // selected_delivery_type: [],
                  // selected_delivery_address: confirm_location.data,
                  action: "save",
                  from: props.from,
                  value: {
                    selected_address: confirm_location.data,
                  },
                };
                console.log("on save send_data :", send_data);
                // set_shipment(send_data);

                props.onClose(send_data);
              }
            }}
          >
            Yes, continue with this location
          </button>
        </div>
      </SwipeableBottomSheet>

      {/* location delete confirm */}
      <SwipeableBottomSheet
        open={confirm_delete.is_open}
        // overlay={true}
        // overflowHeight={0}
        onChange={() => {
          SetConfirmDelete({
            is_open: false,
            data: {},
          });
        }}
      >
        <div className="px-2 py-3 text-center bg-fff sticky-top custom-swipe-shadow">
          <div className="center p-3 ">
            {/* <img src={AppConfig.CDN_Media_url + ""} className="w-30px" /> */}
            <img
              src={
                AppConfig.CDN_Media_url + "fa87db880efda3e5cc5c5d11e350e660.svg"
              }
              className="w-50px"
            />
          </div>
          <div className="loc-head mt-2">
            Are you sure of the delete location?
          </div>

          <button
            className="primary-button mt-3"
            onClick={() => {
              SetConfirmDelete({
                is_open: false,
                data: {},
              });
            }}
          >
            No
          </button>

          <button
            className="primary-button bg-fff w-100 text-primary mt-2"
            onClick={() => {
              delete_saved_address(confirm_delete.data);
            }}
          >
            Yes, delete this location
          </button>
        </div>
      </SwipeableBottomSheet>
    </>
  );
}

export default LocationAddressList;
