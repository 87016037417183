import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams, Navigate, useNavigate } from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import { api } from "../../../utils/Api";

import Skeleton from "react-loading-skeleton";
import { AppConfig } from "../../../config";
import { makeStyles } from "@material-ui/core/styles";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import { Dialog, Modal } from "@material-ui/core";
import { AppHelper } from "../../../utils/Helper";
import LocationAutoCompleteComponent from "../../../components/LocationAutoCompleteComponent/LocationAutoCompleteComponent";
import { mobileApp } from "../../../utils/MobileApp";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },

  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
    },
    overflow: "scroll",
    width: "50%",
  },
}));
let map: any;
let marker: any;

export default function MapLocationPickModal(props: any) {
  const context: any = useContext(DataContext);
  const classes = useStyles();
  let MapLocationPickerModal: any;
  const map_styler = [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];
  let marker_img_url = "https://cdn3.1superapp.com/superapp/deliveryboy.png";
  const navigate = useNavigate();
  const [not_location_enable, SetNotLocationEnable] = useState(false);
  const [confirm_address, SetConfirmAddress] = useState(false);
  const [selected_address, SetSelectedAddress] = useState(true);
  const [is_location_loading, SetIsLocationLoading] = useState(true);
  const [place_details, SetPlaceDetails] = useState(props.place_data);
  const [location_data, SetLocationData] = useState(props.location_data);

  const [location_loading, SetLocationLoading] = useState(false);
  const [continue_loading, SetContinueLoading] = useState(false);
  const [address_type, SetAddressType] = useState({});
  const [address_type_options, SetAddressTypeOptions] = useState([
    {
      value: 1,
      name: "Home",
      disabled: false,
    },
    {
      value: 2,
      name: "Work",
      disabled: false,
    },
    {
      value: 3,
      name: "Others",
      disabled: false,
    },
  ]);
  const [save_address_data, SetSaveAddressData] = useState({
    other_value: "",
    name: "",
    mobile: "",
    house_no: "",
    landmark: "",
  });

  const mapRef = useRef(null);
  // const markerRef: any = useRef(null);
  // let map : any;
  const geocoder = new window.google.maps.Geocoder();
  const [map_lp_modal, SetLocationACModal] = useState({
    is_open: false,
    from: "add_address",
    function: "",
    location_data: {},
    place_data: {},
  });
  const [add_address_dailog_data, SetAddAddressDialogData] = useState({
    is_open: false,
    data: {},
  });
  const Spinner = () => (
    <div className="vendor-list-loader-spinner mx-auto"></div>
  );
  const [delivery_type, SetDeliveryType] = useState([
    {
      img: "6e745f7dbe2d92dcab600140650843b6.png",
      name: "Hyper Local",
      checked: true,
      disabled: false,
    },
    {
      img: "f20407a212488dc88d921b7dacd70e5d.png",
      name: "Inshop",
      checked: false,
      disabled: true,
    },
    {
      img: "dbbbaf218748024fc60be0c19d4d6fb1.png",
      name: "Take Away",
      checked: false,
      disabled: true,
    },
    {
      img: "95baaabf0277cc4ce7630810a0485cdf.png",
      name: "Courier",
      checked: false,
      disabled: true,
    },
  ]);
  const [type_value, SetTypeValue] = useState();
  const [confirm_location, SetConfirmLocation] = useState(false);

  const [save_address_error_data, SetSaveAddressErrorData] = useState({
    other_value: "",
    name: "",
    mobile: "",
    house_no: "",
    landmark: "",
  });

  const circleRef = useRef(null);
  const markerRef = useRef(null);

  const [formData, setFormData] = useState({
    other_value: "",
    house_no: "",
    landmark: "",
    name: "",
    mobile: "",
  });

  const inputRef = useRef(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // let MapTrackermyModal1: any = document.getElementById("MapTracker");
    // MapTrackermyModal1.addEventListener("hidden.bs.modal", function (event: any) {
    //   console.log("event :", event);
    //   // MapLocationPickerModal = "";
    //   props.close();
    // });
    console.log("map page props :", props);
    if (window.google && mapRef.current) {
      InitMap();
    }
  }, []);

  useEffect(() => {
    if (props.open) {
      // MapLocationPickerModal = new bootstrap.Modal(
      //   document.getElementById("MapTracker"),
      //   {
      //     backdrop: false,
      //   }
      // );
      // MapLocationPickerModal.show();
      // Initialize the map once the component has mounted
      if (window.google && mapRef.current) {
        InitMap();
      }
    }
  }, [props.open]);

  useEffect(() => {
    if (not_location_enable === false) {
      setTimeout(() => {
        SetNotLocationEnable(true);
      }, 10000);
    }
  }, [not_location_enable]);

  const [map_position, SetMapPosition] = useState({
    center: {
      lat: 11.342423,
      lng: 77.728165,
    },
    zoom: 13,
  });

  const containerStyle = {
    width: "100%",
    height: "90vh",
  };

  const renderMap = () => {
    window.initMap = initMap;
  };

  // const svgIcon = {
  //   url: `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="%23007bff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"/><path d="M12 8v4l3 3" stroke="%23fff" /></svg>`,
  //   scaledSize: new window.google.maps.Size(40, 40), // Adjust size
  //   anchor: new window.google.maps.Point(20, 40), // Anchor point at the bottom of the icon
  // };

  const svgIcon = {
    url: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 24 24' fill='none' stroke='%23FF0000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 2C8.686 2 6 4.686 6 7c0 3.25 3 6 3 9l3 6 3-6c0 0 3-2.75 3-5 0-2.761-2.686-5-5-5z'/%3E%3Ccircle cx='12' cy='7' r='2' fill='%23FF0000'/%3E%3C/svg%3E",        scaledSize: new window.google.maps.Size(50, 50),
    anchor: new window.google.maps.Point(25, 50), // Center the icon at the bottom
  };

  function InitMap(): void {
    map = new google.maps.Map(
      document.getElementById("map-loction-pick") as HTMLElement,
      {
        zoom: 18,
        center: {
          lat: Number(props.location_data.lat),
          lng: Number(props.location_data.lng),
        },
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false,
        mapTypeId: "roadmap",
        gestureHandling: "greedy",
        styles: map_styler,
      }
    );

    SetLocationData({
      lat: Number(props.location_data.lat),
      lng: Number(props.location_data.lng),
    });

    markerRef.current = new window.google.maps.Marker({
      // position,
      // position: {
      //   lat: Number(props.location_data.lat),
      //   lng: Number(props.location_data.lng),
      // },
      // icon: svgIcon,
      map: map,
    });

    if (props.from != "add_address") {
      // marker.setMap(null);
      // marker = new window.google.maps.Marker({
      //   position: {
      //     lat: Number(props.location_data.lat),
      //     lng: Number(props.location_data.lng),
      //   },
      //   map: map,
      //   // icon: {
      //   //   url: "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless.png", // Custom icon URL
      //   //   labelOrigin: new window.google.maps.Point(0, 0), // Position label below the marker
      //   // },
      // });
      // marker.setMap(map);

      markerRef.current.setPosition({
        lat: Number(props.location_data.lat),
        lng: Number(props.location_data.lng),
      });

      // circleRef.current = new window.google.maps.Circle({
      //   strokeColor: "#4285F4",
      //   strokeOpacity: 0.8,
      //   strokeWeight: 2,
      //   fillColor: "#4285F4",
      //   fillOpacity: 0.35,
      //   map: map,
      //   center: {
      //     lat: Number(props.location_data.lat),
      //     lng: Number(props.location_data.lng),
      //   },
      //   radius: 50, // Adjust this value for the size of the circle
      // });

      // markerRef.current = new window.google.maps.Marker({
      //   position: {
      //     lat: Number(props.location_data.lat),
      //     lng: Number(props.location_data.lng),
      //   },
      //   map: map,
      //   animation: window.google.maps.Animation.DROP, // Bounce animation
      //   icon: {
      //     path: window.google.maps.SymbolPath.CIRCLE, // Use a circle as the icon
      //     scale: 10, // Adjust scale for icon size
      //     fillColor: '#4285F4', // Blue color
      //     fillOpacity: 1,
      //     strokeWeight: 2,
      //     strokeColor: '#ffffff', // White stroke around the marker
      //   },
      // });

      // // Animate the circle (simulate pulsing)
      // let growing = true;
      // setInterval(() => {
      //   let radius = circleRef.current.getRadius();
      //   if (growing) {
      //     radius += 2;
      //     if (radius >= 100) growing = false;
      //   } else {
      //     radius -= 2;
      //     if (radius <= 50) growing = true;
      //   }
      //   circleRef.current.setRadius(radius);
      // }, 100); // Pulse speed (adjust for smoother animation)

      // const service = new window.google.maps.places.PlacesService(map);

      // const request = {
      //   query: props.location_data.lat + "," + props.location_data.lng,
      //   // fields: ["name", "formatted_address"],
      //   fields: ["formatted_address", "geometry", "name"],
      //   strictBounds: false,
      //   types: ["establishment"],
      // };
      // console.log("request :", request);

      // service.textSearch(request, (results: any, status: any) => {
      //   console.log("results :", results);
      //   console.log("status :", status);
      //   if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      //     SetPlaceDetails((prevalue: any) => {
      //       prevalue = results[0];
      //       console.log("prevalue results[0] :", prevalue);
      //       return prevalue;
      //     });
      //   } else {
      //     SetPlaceDetails({});
      //   }
      //   SetIsLocationLoading(false);
      // });

      let send_data = props.location_data.lat + "," + props.location_data.lng;
      make_placeservice(send_data);
    } else {
      getLocation();
      SetContinueLoading(true);
    }

    // custome marker

    // const markerDiv = document.createElement("div");
    // markerDiv.className = "custom-marker";

    // // Add the image inside the marker
    // // const img = document.createElement("img");
    // // img.src =
    // //   "https://cdn3.1superapp.com/media/faa239521f14ddebaa408cbfc8b6d512.svg";
    // // img.className = "marker-image";

    // // Add the label inside the marker
    // const labelDiv = document.createElement("div");
    // labelDiv.className = "marker-image";
    // labelDiv.innerText = "Orders will be delivered here";

    // // Add the label inside the marker
    // const labelDiv1 = document.createElement("div");
    // labelDiv1.className = "marker-label";
    // labelDiv1.innerText = "Place the pin accurately on the map";

    // // markerDiv.appendChild(img);
    // markerDiv.appendChild(labelDiv);
    // markerDiv.appendChild(labelDiv1);

    // // Create a custom overlay for the marker
    // const overlay = new window.google.maps.OverlayView();
    // overlay.onAdd = function () {
    //   const panes: any = this.getPanes();
    //   panes.overlayMouseTarget.appendChild(markerDiv);
    // };
    // overlay.draw = function () {
    //   const projection = this.getProjection();
    //   const position: any = projection.fromLatLngToDivPixel(
    //     new window.google.maps.LatLng(
    //       props.location_data.lat,
    //       props.location_data.lng
    //     )
    //   );
    //   markerDiv.style.position = "absolute";
    //   markerDiv.style.left = `${
    //     position.x - markerDiv.offsetWidth / 2
    //   }px`;
    //   markerDiv.style.top = `${
    //     position.y - markerDiv.offsetHeight / 2
    //   }px`;
    // };
    // overlay.onRemove = function () {
    //   markerDiv.remove();
    // };
    // overlay.setMap(map);

    // }

    map.addListener("click", (e: any) => {
      const latLng = e.latLng;
      SetIsLocationLoading(true);
      SetLocationData({ lat: e.latLng.lat(), lng: e.latLng.lng() });

      // if (marker && marker.setMap) {
      //   marker.setMap(null); // Remove previous marker
      // }
      // marker.setMap(null); // Remove previous marker
      // marker = new window.google.maps.Marker({
      //   position: latLng,
      //   map: map,
      // });
      markerRef.current.setPosition(latLng);

      // markerRef.current = marker;

      // let send_data = {
      //   location: latLng,
      // };
      // if(AppHelper.GetAddressByLocation(send_data) != ""){

      // }
      let send_data = e.latLng.lat() + "," + e.latLng.lng();
      make_placeservice(send_data);
    });
  }

  function make_placeservice(query_data: any) {
    const service = new window.google.maps.places.PlacesService(map);

    const request = {
      query: query_data,
      // fields: ["name", "formatted_address"],
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["establishment"],
      // radius: 500,
      // types: ["street_address"],
    };
    console.log("request :", request);
    service.textSearch(request, (results: any, status: any) => {
      console.log("results :", results);
      console.log("status :", status);
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        let result_data = results[0];
        if (result_data) {
          const AddressData = extractNameAndRemovePlusCode(
            result_data.vicinity || result_data.formatted_address
          );
          SetPlaceDetails((prevalue: any) => {
            prevalue = result_data;
            console.log("prevalue results[0] :", prevalue);
            console.log("prevalue results[0] AddressData :", AddressData);
            prevalue.own_address_data = AddressData;
            return prevalue;
          });
          SetIsLocationLoading(false);
        } else {
          SetPlaceDetails({});
          SetIsLocationLoading(false);
        }
      } else {
        console.error("PlacesService error:", status);
      }
    });
  }

  const extractNameAndRemovePlusCode = (address: any) => {
    // Regular expression to match and remove Plus Code patterns
    const cleanedAddress = address
      .replace(/\b\w{4}\+\w{2,4}\s?,?\s?/g, "")
      .trim();

    // // Split the cleaned address at the first comma to get the name
    // const name = cleanedAddress.split(',')[0].trim();

    const parts = cleanedAddress.split(",");

    // Regular expression to identify a door or house number pattern (e.g., "12", "12/1")
    // const doorNumberPattern = /^(\d+\/)*\d+\s?[A-Za-z]?$/;
    // const doorNumberPattern = /^(\d+([\/-]\d+)*)([-\s]?[A-Za-z])?$/;
    const doorNumberPattern =
      /^(\d+[a-zA-Z]*(\/\d+[a-zA-Z]*)*)([-\s]?[A-Za-z]*)?$/;

    // Check if the first part matches the door number pattern
    let shortAddress;
    if (doorNumberPattern.test(parts[0].trim())) {
      // If the first part looks like a door number, use the second part
      shortAddress = parts[1] ? parts[1].trim() : parts[0].trim();
    } else {
      // Otherwise, use the first part as the short address
      shortAddress = parts[0].trim();
    }

    return { short_address: shortAddress, formatted_address: cleanedAddress };
  };

  function GetAddressByLocation(get_data: any) {
    let return_data = "";
    geocoder.geocode(
      { location: get_data.location },
      (results: any, status) => {
        if (status === "OK" && results[0]) {
          // setAddress(results[0].formatted_address);
          return_data = results[0].formatted_address;
        } else {
          // setAddress("No address found");
          return_data = "";
        }
      }
    );
    return return_data;
  }

  function getLocation() {
    document.addEventListener("message", putLocationFromMobile);
    if (context.app_data.application.platform == "web") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, errorCallback);
      }
    } else if (
      context.app_data.application.platform == "android" ||
      context.app_data.application.platform == "ios"
    ) {
      mobileApp({
        function: "getlocationlatlng",
        app_type: "react_web",
        accuracy: "Highest",
      });
    }
  }

  function putLocationFromMobile(message: any) {
    SetLocationLoading(false);
    SetContinueLoading(false);
    if (message.data.function == "getlocationlatlng") {
      // if (message.data.status == "success") {
      if (
        message.data.lat != null &&
        message.data.lng != null &&
        message.data.lat != undefined &&
        message.data.lng != undefined
      ) {
        let lat = message.data.lat;
        let lng = message.data.lng;
        // SetMapLPModal({
        //   is_open: true,
        //   from: "add_address",
        //   function: "get_current_location",
        //   location_data: {
        //     lat: lat,
        //     lng: lng,
        //   },
        //   place_data: {
        //     formatted_address: "",
        //   },
        // });
        SetLocationData({ lat: lat, lng: lng });

        // normal marker start
        map.setCenter({ lat: lat, lng: lng });
        map.setZoom(10);

        // marker.setMap(null);
        // marker = new window.google.maps.Marker({
        //   position: {
        //     lat: lat,
        //     lng: lng,
        //   },
        //   map: map,
        //   // icon: {
        //   //   url: "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless.png", // Custom icon URL
        //   //   labelOrigin: new window.google.maps.Point(0, 0), // Position label below the marker
        //   // },
        // });
        // marker.setMap(map);
        markerRef.current.setPosition({
          lat: lat,
          lng: lng,
        });

        // normal marker end

        // circleRef.current = new window.google.maps.Circle({
        //   strokeColor: "#4285F4",
        //   strokeOpacity: 0.8,
        //   strokeWeight: 2,
        //   fillColor: "#4285F4",
        //   fillOpacity: 0.35,
        //   map: map,
        //   center: { lat: lat, lng: lng },
        //   radius: 50, // Adjust this value for the size of the circle
        // });

        // // Animate the circle (simulate pulsing)
        // let growing = true;
        // setInterval(() => {
        //   let radius = circleRef.current.getRadius();
        //   if (growing) {
        //     radius += 2;
        //     if (radius >= 100) growing = false;
        //   } else {
        //     radius -= 2;
        //     if (radius <= 50) growing = true;
        //   }
        //   circleRef.current.setRadius(radius);
        // }, 100); // Pulse speed (adjust for smoother animation)

        // const service = new window.google.maps.places.PlacesService(map);

        // const request = {
        //   query: lat + "," + lng,
        //   // fields: ["name", "formatted_address"],
        //   fields: ["formatted_address", "geometry", "name"],
        //   strictBounds: false,
        //   types: ["establishment"],
        // };
        // console.log("request :", request);
        // service.textSearch(request, (results: any, status: any) => {
        //   console.log("results :", results);
        //   console.log("status :", status);
        //   if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        //     SetPlaceDetails((prevalue: any) => {
        //       prevalue = results[0];
        //       console.log("prevalue results[0] :", prevalue);
        //       return prevalue;
        //     });
        //     SetIsLocationLoading(false);
        //   } else {
        //     SetPlaceDetails({});
        //     SetIsLocationLoading(false);
        //   }
        // });

        let send_data = lat + "," + lng;
        make_placeservice(send_data);
      } else {
        // map.setCenter({ lat: "11.3410", lng: "77.7172" });
        // alert("123");
        // alert("Enable Location");
        console.log("enable location");
      }
    }

    document.removeEventListener("message", () => {});
  }

  function showPosition(
    position: any,
    placeLocationMarker: any = true,
    placeMarker: any = false
  ) {
    SetLocationLoading(false);
    SetContinueLoading(false);
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    if (context.app_data.have_mobile_app_key) {
      AppHelper.SaveLocation(position);
    }
    // SetMapLPModal({
    //   is_open: true,
    //   from: "add_address",
    //   function: "get_current_location",
    //   location_data: {
    //     lat: lat,
    //     lng: lng,
    //   },
    //   place_data: {
    //     formatted_address: "",
    //   },
    // });
    SetLocationData({ lat: lat, lng: lng });

    map.setCenter({ lat: lat, lng: lng });

    // marker = new window.google.maps.Marker({
    //   position: {
    //     lat: lat,
    //     lng: lng,
    //   },
    //   map: map,
    //   // icon: {
    //   //   url: "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless.png", // Custom icon URL
    //   //   labelOrigin: new window.google.maps.Point(0, 0), // Position label below the marker
    //   // },
    // });
    // marker.setMap(null);
    // marker.setMap(map);

    markerRef.current.setPosition({
      lat: lat,
      lng: lng,
    });

    // ////

    // circleRef.current = new window.google.maps.Circle({
    //   strokeColor: "#4285F4",
    //   strokeOpacity: 0.8,
    //   strokeWeight: 2,
    //   fillColor: "#4285F4",
    //   fillOpacity: 0.35,
    //   map: map,
    //   center: { lat: lat, lng: lng },
    //   radius: 50, // Adjust this value for the size of the circle
    // });

    // // Animate the circle (simulate pulsing)
    // let growing = true;
    // setInterval(() => {
    //   let radius = circleRef.current.getRadius();
    //   if (growing) {
    //     radius += 2;
    //     if (radius >= 100) growing = false;
    //   } else {
    //     radius -= 2;
    //     if (radius <= 50) growing = true;
    //   }
    //   circleRef.current.setRadius(radius);
    // }, 100); // Pulse speed (adjust for smoother animation)

    // const service = new window.google.maps.places.PlacesService(map);

    // const request = {
    //   query: lat + "," + lng,
    //   // fields: ["name", "formatted_address"],
    //   fields: ["formatted_address", "geometry", "name"],
    //   strictBounds: false,
    //   types: ["establishment"],
    // };
    // console.log("request :", request);
    // service.textSearch(request, (results: any, status: any) => {
    //   console.log("results :", results);
    //   console.log("status :", status);
    //   if (status === window.google.maps.places.PlacesServiceStatus.OK) {
    //     SetPlaceDetails((prevalue: any) => {
    //       prevalue = results[0];
    //       console.log("prevalue results[0] :", prevalue);
    //       return prevalue;
    //     });
    //   } else {
    //     SetPlaceDetails({});
    //   }
    //   SetIsLocationLoading(false);
    // });
    let send_data = lat + "," + lng;
    make_placeservice(send_data);
  }

  function errorCallback(error: any) {
    // alert("Enable Location");
    SetLocationLoading(false);
    SetContinueLoading(false);
    console.log("error :", error);
    console.log("props :", props);
  }

  async function set_shipment(get_data: any) {
    console.log("set_shipment get_data :", get_data);
    console.log(
      "set shipment context.app_data.selected_section :",
      context.app_data.selected_section
    );

    let pass_data: any = {
      post: {},
    };
    let selected_delivery_type: any = [];
    delivery_type?.map((ele) => {
      if (ele.checked) {
        selected_delivery_type.push(ele);
      }
    });
    selected_delivery_type?.map((ele: any) => {
      if (ele.value == "in_shop") {
        if (ele.checked) {
          pass_data.post.inshop = 1;
        } else {
          pass_data.post.inshop = 0;
        }
      }
      if (ele.value == "hyper_local") {
        if (ele.checked) {
          pass_data.post.hyperlocal = 1;
        } else {
          pass_data.post.hyperlocal = 0;
        }
      }
      if (ele.value == "take_away") {
        if (ele.checked) {
          pass_data.post.takeaway = 1;
        } else {
          pass_data.post.takeaway = 0;
        }
      }
      if (ele.value == "courier") {
        if (ele.checked) {
          pass_data.post.courier = 1;
        } else {
          pass_data.post.courier = 0;
        }
      }
    });

    if (get_data.from == "location_picker") {
      pass_data.post.lat = get_data.picked_lat_long.lat;
      pass_data.post.lng = get_data.picked_lat_long.lng;
      pass_data.post.map_address = get_data.picked_address;
      pass_data.post.short_map_address = get_data.short_map_address;
    }
    if (get_data.from == "select_address") {
      pass_data.post.delivery_address = get_data.selected_delivery_address.id;
    }
    if (Object.keys(context.app_data.selected_section).length > 0) {
      pass_data.post.section_slug = context.app_data.selected_section.slug;
    }
    console.log("res set_shipment pass_data :", pass_data);
    let response = await api("/set_shipment", pass_data);
    console.log("res set_shipment :", response);
    if (response.status_code == 200) {
      context.SetAppData((prevalue: any) => {
        prevalue.shipment_details = response.response.shipment_details;
        if (get_data.from == "location_picker") {
          prevalue.lat_lang = {
            lat: get_data.picked_lat_long.lat,
            lang: get_data.picked_lat_long.lng,
          };
        }
        if (get_data.from == "select_address") {
          prevalue.lat_lang = {
            lat: get_data.selected_delivery_address.latitude,
            lang: get_data.selected_delivery_address.longitude,
          };
        }
        return { ...prevalue };
      });
      Cookies.set(
        "shipment_data",
        JSON.stringify(response.response.shipment_details)
      );

      if (get_data.from == "location_picker") {
        Cookies.set(
          "location",
          JSON.stringify({
            lat: get_data.picked_lat_long.lat,
            lang: get_data.picked_lat_long.lng,
          })
        );
      }
      if (get_data.from == "select_address") {
        Cookies.set(
          "location",
          JSON.stringify({
            lat: get_data.selected_delivery_address.latitude,
            lang: get_data.selected_delivery_address.longitude,
          })
        );
      }
    }
    let send_data = {
      action: "save",
    };
    props.onClose(send_data);
  }

  async function SaveAddress(get_data: any) {
    let address_value = get_data;

    console.log("address_value :", address_value);

    let pass_data: any = {
      post: {
        name: formData.name,
        mobile: formData.mobile,
        door_no: formData.house_no,
        landmark: formData.landmark,
        latitude: location_data.lat,
        longitude: location_data.lng,
        // map_address: place_details?.formatted_address,
        map_address: place_details?.own_address_data?.formatted_address,
        // short_map_address: place_details?.name,
        short_map_address: place_details?.own_address_data?.short_address,
      },
    };
    if (address_type.value != 3) {
      pass_data.post.type = address_type.value;
    } else {
      pass_data.post.other_type = formData.other_value;
    }
    console.log("Save Address passdata : ", pass_data);
    let data_res = await api("/add_delivery_address", pass_data);
    console.log("res add_delivery_address :", data_res);
    if (data_res.status_code === 200) {
      context.SetAppData((prevValue: any) => {
        console.log("Home SetAppData prevValue :", prevValue);
        prevValue.delivery_address = data_res.response.delivery_address;
        return { ...prevValue };
      });
      props.onClose({
        action: "address_save",
        address_value: data_res.response.delivery_address,
      });
    }
  }

  // function validate_save_address_data(get_data: any) {
  //   console.log("validate_save_address_data get_data : ", get_data);

  //   const indianMobileRegex = /^(\+91)?[6-9]\d{9}$/;

  //   let error_value: any = {
  //     other_value: "",
  //     name: "",
  //     mobile: "",
  //     house_no: "",
  //     landmark: "",
  //   };
  //   if (address_type.name == "Others") {
  //     if (get_data.other_value != "") {
  //       error_value.other_value = "";
  //     } else {
  //       error_value.other_value = "error";
  //     }
  //   }

  //   // if (get_data.name != "") {
  //   //   error_value.name = "";
  //   // } else {
  //   //   error_value.name = "error";
  //   // }

  //   // if (get_data.mobile != "") {
  //   //   error_value.mobile = "";
  //   // } else {
  //   //   error_value.mobile = "error";
  //   // }

  //   // if (indianMobileRegex.test(get_data.mobile)) {
  //   //   error_value.mobile = "";
  //   // } else {
  //   //   error_value.mobile = "error";
  //   // }

  //   if (get_data.house_no != "") {
  //     error_value.house_no = "";
  //   } else {
  //     error_value.house_no = "error";
  //   }

  //   if (get_data.landmark != "") {
  //     error_value.landmark = "";
  //   } else {
  //     error_value.landmark = "error";
  //   }

  //   SetSaveAddressErrorData(error_value);

  //   if (
  //     error_value.other_value ||
  //     error_value.name ||
  //     error_value.mobile ||
  //     error_value.house_no ||
  //     error_value.landmark
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }

  const validateField = (name: any, value: any) => {
    const indianMobileRegex = /^(\+91)?[6-9]\d{9}$/;
    switch (name) {
      case "other_value":
        return value.length > 0 ? "" : "This field is required";
      case "house_no":
        return value.length > 0 ? "" : "This field is required";
      case "landmark":
        return value.length > 0 ? "" : "This field is required";
      case "name":
        return value.length === 0 || value.length > 0 ? "" : "This field allowed in name";
      case "mobile":
        return value.length === 0 || indianMobileRegex.test(value)
          ? ""
          : "Mobile number should be 10 digits";
      // return /^\d{10}$/.test(value) ? '' : 'Mobile number should be 10 digits';
      default:
        return "";
    }
  };

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleSubmit = () => {
    // e.preventDefault();
    // Perform final validation check before submission
    const newErrors = Object.keys(formData).reduce((acc, field) => {
      const error = validateField(field, formData[field]);
      if (error) acc[field] = error;
      return acc;
    }, {});

    SetConfirmAddress(false);
    SetAddAddressDialogData({
      is_open: false,
    });

    if (Object.keys(newErrors).length === 0) {
      console.log("Form data submitted:", formData);
      // Proceed with form submission (e.g., API call)

      if (props.from != "edit_address") {
        SaveAddress(save_address_data);
      } else {
        toast.error("Edit on Process");
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <>
      {/* <div
        className="modal fade"
        id="MapTracker"
        tabIndex={-1}
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      > */}
      {/* <div className="modal-dialog  modal-fullscreen" data-backdrop="false"> */}
      {/* <div className="modal-content"> */}
      <div className="scrolling-card overflowY-max card p-0">
        {/* header */}
        <div className="header header-fixed-top header-badge-padd border-bottom-1 d-flex align-items-center custom-container px-2 ">
          <div
            className="ps-1 pe-2 cursor"
            onClick={() => {
              props.onClose({ action: "close" });
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "30549c56761b976b23418471a0f0037a.svg"
              }
              className="w-14px"
            />
          </div>
          <div className="ps-1 pe-0 w-100">
            <div className="loc-head">Confirm delivery location</div>
          </div>
        </div>
        {/* header */}
        {/* <div className="modal-header address-map-header shadow px-2">
              <div
                className="px-2 cursor"
                data-bs-dismiss="modal"
                onClick={() => {
                  let close_data = {
                    action: "close",
                    from: props.from,
                    function: props.function,
                    location_data: props.location_data,
                    place_data: props.place_data,
                  };
                  props.close(close_data);
                }}
              >
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "30549c56761b976b23418471a0f0037a.svg"
                  }
                  className="w-14px"
                />
              </div>
              <div className="ps-1 pe-0 w-100">
                <p className="loc-head"> Confirm delivery location</p>
              </div>
            </div> */}

        {/* modal body */}
        {/* <div className="modal-body p-0"> */}
        <div className="custom-container mt-5">
          <div className="map-top-div custom-container px-md-2">
            {/* search */}
            <div className="address-search-bar d-flex align-items-center mt-3">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "4075afa7aafecff54a5220213ad396f0.svg"
                }
                className="w-20px"
              />
              <div
                className="ps-2 "
                onClick={() => {
                  console.log("on click props :", props);
                  if (
                    props.from == "add_address" ||
                    props.from == "edit_address"
                  ) {
                    SetLocationACModal({
                      is_open: true,
                      from: "search",
                      function: "no_function",
                      location_data: {
                        lat: location_data.lat,
                        lng: location_data.lng,
                      },
                      place_data: place_details,
                    });
                  } else {
                    props.onClose({
                      action: "close",
                    });
                  }
                }}
              >
                <input
                  type="text"
                  ref={inputRef}
                  className=" custom-input-text"
                  placeholder="Search by area, building, or address"
                  onClick={() => {
                    console.log("on inpuct click ", props);
                    // navigate("/AddAddressNew");
                    if (props.from == "search") {
                      // props.close();
                    }
                    if (props.from == "add_address") {
                      console.log(
                        "open modal for search address with out add location"
                      );
                    }
                  }}
                />
              </div>
              <div className="ms-auto cursor ps-2">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "d6da0c9c3517352622470b635f8a8d38.svg"
                  }
                  className="w-20px"
                />
              </div>
            </div>
          </div>
          <div id="map-loction-pick" ref={mapRef}></div>
          <div className="custom-map-bottom-div">
            <div className="w-100 center mb-3">
              <div
                className="d-flex align-items-center px-3 py-2 mb-0 bg-fff shadow radius-10"
                onClick={() => {
                  SetLocationLoading((prevalue) => {
                    if (!prevalue) {
                      getLocation();
                      SetContinueLoading(true);
                      prevalue = true;
                    } else {
                      console.log("location loading");
                    }
                    return prevalue;
                  });
                }}
              >
                {location_loading ? (
                  <Spinner />
                ) : (
                  <>
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "deeb734ab7f87d4cfb4dd6192016c1a4.svg"
                      }
                      className="w-14px me-2"
                    />
                    <div className="text-black-color let_space_03 fw-bold  fs-12px">
                      Pick my location
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* <Dialog
                  open={selected_address}
                  classes={{ container: classes.root, paper: classes.paper }}
                  onClose={() => {
                    SetSelectedAddress(false);
                  }}
                > */}
            {/* {selected_address ? ( */}
            <div className="p-3 shadow radius-10 bg-fff  custom-container">
              <div className="address-sub-text text-black-color mb-3">
                Selected address
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-flex-start">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "faa239521f14ddebaa408cbfc8b6d512.svg"
                    }
                    className="w-14px me-2"
                  />
                  {!is_location_loading ? (
                    // <div className="location-head">{place_details?.name}</div>
                    <div className="location-head">
                      {place_details?.own_address_data?.short_address}
                    </div>
                  ) : (
                    <div className="location-head">
                      <Skeleton height="10px" width="50%" />
                    </div>
                  )}
                </div>
                {!is_location_loading ? (
                  <div className="address-sub-text mt-1">
                    {/* {place_details?.formatted_address} */}
                    {place_details?.own_address_data?.formatted_address}
                  </div>
                ) : (
                  <div className="address-sub-text mt-1">
                    <Skeleton height="8px" width="100%" />
                    <Skeleton height="8px" width="50%" />
                  </div>
                )}
              </div>

              <button
                className="primary-button mb-3"
                disabled={is_location_loading}
                onClick={() => {
                  // SetSelectedAddress(false);
                  // SetConfirmAddress(true);
                  console.log("is_location_loading", is_location_loading);
                  console.log("is_location_loading props", props);

                  if (
                    props.from == "add_address" ||
                    props.from == "edit_address"
                  ) {
                    SetAddAddressDialogData({
                      is_open: true,
                      data: props.place_data,
                    });
                    if (props.from == "edit_address") {
                      if (props.place_data.type != null) {
                        if (props.place_data.type == "1") {
                          SetAddressType({
                            value: Number(props.place_data.type),
                            name: "Home",
                          });
                        }
                        if (props.place_data.type == "2") {
                          SetAddressType({
                            value: Number(props.place_data.type),
                            name: "Work",
                          });
                        }
                      } else {
                        SetAddressType({
                          value: 3,
                          name: "Others",
                        });
                      }
                      let save_data: any = {
                        name: props.place_data.name,
                        mobile: props.place_data.mobile,
                        house_no: props.place_data.door_no,
                        landmark: props.place_data.landmark,
                      };
                      if (props.place_data.type == null) {
                        save_data.other_value = props.place_data.other_type;
                      }
                      SetSaveAddressData(save_data);
                    } else {
                      let address_data = context.app_data.delivery_address;
                      let is_home_available = address_data.some(
                        (ele: any) => ele.type == "1"
                      );
                      let is_work_available = address_data.some(
                        (ele: any) => ele.type == "2"
                      );
                      if (is_home_available && is_work_available) {
                        SetAddressType({
                          value: 3,
                          name: "Others",
                        });
                        SetAddressTypeOptions((prevalue) => {
                          prevalue.map((ele: any) => {
                            if (ele.value == "1" || ele.value == "2") {
                              ele.disable = true;
                            }
                          });
                          return [...prevalue];
                        });
                      } else {
                        if (is_home_available) {
                          SetAddressType({
                            value: 2,
                            name: "Work",
                          });
                          SetAddressTypeOptions((prevalue) => {
                            prevalue.map((ele: any) => {
                              if (ele.value == "1") {
                                ele.disable = true;
                              }
                            });
                            return [...prevalue];
                          });
                        } else if (is_work_available) {
                          SetAddressType({
                            value: 1,
                            name: "Home",
                          });
                          SetAddressTypeOptions((prevalue) => {
                            prevalue.map((ele: any) => {
                              if (ele.value == "2") {
                                ele.disable = true;
                              }
                            });
                            return [...prevalue];
                          });
                        } else {
                          SetAddressType({
                            value: 1,
                            name: "Home",
                          });
                          SetAddressTypeOptions((prevalue) => {
                            prevalue.map((ele: any) => {
                              ele.disable = false;
                            });
                            return [...prevalue];
                          });
                        }
                      }
                    }
                  } else {
                    // SetConfirmLocation(true);
                    if (props.from == "get_location_to_save") {
                      let send_data = {
                        action: props.from,
                        lat: location_data.lat,
                        lng: location_data.lng,
                        // full_address: place_details?.formatted_address,
                        full_address:
                          place_details?.own_address_data?.formatted_address,
                        // short_address: place_details?.name,
                        short_address:
                          place_details?.own_address_data?.short_address,
                      };
                      props.onClose(send_data);
                    } else if (props.from == "search") {
                      if (props.location_list_props_from == "header") {
                        let send_data = {
                          from: "location_picker",
                          picked_lat_long: location_data,
                          // picked_address: place_details?.formatted_address,
                          picked_address:
                            place_details?.own_address_data?.formatted_address,
                          // short_map_address: place_details?.name,
                          short_map_address:
                            place_details?.own_address_data?.short_address,
                        };
                        console.log("on save send_data :", send_data);
                        set_shipment(send_data);
                      } else {
                        let send_data = {
                          action: props.from,
                          lat: location_data.lat,
                          lng: location_data.lng,
                          // full_address: place_details?.formatted_address,
                          full_address:
                            place_details?.own_address_data?.formatted_address,
                          // short_address: place_details?.name,
                          short_address:
                            place_details?.own_address_data?.short_address,
                        };
                        props.onClose(send_data);
                      }
                    } else {
                      let send_data = {
                        from: "location_picker",
                        picked_lat_long: location_data,
                        // picked_address: place_details?.formatted_address,
                        picked_address:
                          place_details?.own_address_data?.formatted_address,
                        // short_map_address: place_details?.name,
                        short_map_address:
                          place_details?.own_address_data?.short_address,
                      };
                      console.log("on save send_data :", send_data);
                      set_shipment(send_data);
                    }
                    console.log("save location");
                  }
                }}
              >
                Confirm Delivery Address
              </button>
            </div>
            {/* ) : null} */}

            {/* </Dialog> */}
          </div>
        </div>

        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>

      {map_lp_modal.is_open && (
        <Modal open={map_lp_modal.is_open}>
          <LocationAutoCompleteComponent
            // open={map_lp_modal.is_open}
            from={map_lp_modal.from}
            location_data={map_lp_modal.location_data}
            place_data={map_lp_modal.place_data}
            onClose={(data: any) => {
              console.log("LocationAutoCompleteComponent on close :", data);
              SetLocationACModal({
                is_open: false,
                from: "",
                function: "",
                location_data: {},
                place_data: {},
              });
              // if (inputRef.current) {
              //   inputRef.current.blur();
              // }
              inputRef.current.blur();

              if (data.action == "save") {
                SetPlaceDetails(data.place_data);
                SetIsLocationLoading(true);
                SetLocationData(data.location_data);
                // if (marker && marker.setMap) {
                //   marker.setMap(null); // Remove previous marker
                // }
                // marker.setMap(null); // Remove previous marker
                // marker = new window.google.maps.Marker({
                //   position: {
                //     lat: data.location_data.lat,
                //     lng: data.location_data.lng,
                //   },
                //   map: map,
                // });

                // marker.setMap(map);
                markerRef.current.setPosition({
                  lat: data.location_data.lat,
                  lng: data.location_data.lng,
                });

                map.setCenter({
                  lat: data.location_data.lat,
                  lng: data.location_data.lng,
                });

                // const service = new window.google.maps.places.PlacesService(
                //   map
                // );

                // const request = {
                //   query: data.location_data.lat + "," + data.location_data.lng,
                //   // fields: ["name", "formatted_address"],
                //   fields: ["formatted_address", "geometry", "name"],
                //   strictBounds: false,
                //   types: ["establishment"],
                // };
                // console.log("request :", request);
                // service.textSearch(request, (results: any, status: any) => {
                //   console.log("results :", results);
                //   console.log("status :", status);
                //   if (
                //     status === window.google.maps.places.PlacesServiceStatus.OK
                //   ) {
                //     SetPlaceDetails((prevalue: any) => {
                //       prevalue = results[0];
                //       console.log("prevalue results[0] :", prevalue);
                //       return prevalue;
                //     });
                //   } else {
                //     SetPlaceDetails({});
                //   }
                // });
                let send_data =
                  data.location_data.lat + "," + data.location_data.lng;
                make_placeservice(send_data);
                SetIsLocationLoading(false);
              }
            }}
          />
        </Modal>
      )}

      {/* add address dialog */}
      {add_address_dailog_data.is_open && (
        <Dialog
          open={add_address_dailog_data.is_open}
          classes={{ container: classes.root, paper: classes.paper }}
          // onClose={() => {
          //   SetConfirmAddress(false);
          // }}
        >
          {/* {confirm_address ? ( */}
          <div className="p-3 shadow radius-10 bg-fff">
            {/* <div className="center d-md-none d-sm-block pb-1">
              <hr className="hr-drawer" />
            </div> */}
            <div className=" mb-3">
              {/* center */}
              <div className="head-text">Enter address details</div>
            </div>

            <div className="bg-body-color p-2 radius-10 d-flex mb-2">
              <div className="">
                {/* <div className="address-text">{place_details?.name}</div> */}
                <div className="address-text">
                  {place_details?.own_address_data.short_address}
                </div>
                <div className="address-sub-text">
                  {/* {place_details?.formatted_address} */}
                  {place_details?.own_address_data.formatted_address}
                </div>
              </div>
              <div
                className="ms-auto"
                onClick={() => {
                  SetAddAddressDialogData({
                    is_open: false,
                    data: {},
                  });
                }}
              >
                <div className="text-primary let_space_03 fw-bold px-2 py-1 mb-0 bg-fff shadow radius-10 fs-10px">
                  CHANGE
                </div>
              </div>
            </div>

            <div className="small-text-primary text-center mb-2">
              Share a detailed address it helps our delivery partner reach your
              doorstep
            </div>

            <div className="address-sub-text mb-2">Save as *</div>
            <div className="d-flex align-items-center mb-2">
              {address_type_options.map((ele: any) => (
                <div
                  className={
                    address_type.name == ele.name
                      ? "active-address-card me-3"
                      : ele.disable
                      ? "disable-address-card me-3"
                      : "address-card me-3"
                  }
                  onClick={() => {
                    if (!ele.disable) {
                      SetAddressType(ele);
                    }
                  }}
                >
                  {ele.name == "Home" ? (
                    <img
                      src={
                        address_type.name == ele.name
                          ? AppConfig.CDN_Media_url +
                            "b29855d10e55dea0d062b682e7d5eae5.svg"
                          : AppConfig.CDN_Media_url +
                            "37465a03db5aa15f878902d80b06a7bf.svg"
                      }
                      className="w-14px h-14px me-2"
                    />
                  ) : ele.name == "Work" ? (
                    <img
                      src={
                        address_type.name == ele.name
                          ? AppConfig.CDN_Media_url +
                            "292c10ccdcfa50f6b4432ca5a574ece6.svg"
                          : AppConfig.CDN_Media_url +
                            "ea3940e6ca383c12c50072e001d40f34.svg"
                      }
                      className="w-14px h-14px me-2"
                    />
                  ) : ele.name == "Others" ? (
                    <img
                      src={
                        address_type.name == ele.name
                          ? AppConfig.CDN_Media_url +
                            "a602ae32e1e68c6c644519e06704bca6.svg"
                          : AppConfig.CDN_Media_url +
                            "079b9534b18619f46fbcf371a7af08bd.svg"
                      }
                      className="w-14px h-14px me-2"
                    />
                  ) : null}
                  {ele.name}
                </div>
              ))}
            </div>

            <div className="mb-4">
              {/* Other active should show this field */}
              {address_type.name == "Others" && (
                <div className="position-relative mt-4">
                  <input
                    type="text"
                    className="outside"
                    name="other_value"
                    value={save_address_data.other_value}
                    onChange={(e: any) => {
                      console.log("on change :", e);
                      SetSaveAddressData((prevalue: any) => {
                        prevalue.other_value = e.target.value;
                        // validate_save_address_data(prevalue);
                        handleFieldChange(e);
                        return { ...prevalue };
                      });
                    }}
                  />
                  {errors.other_value ? (
                    <span className="floating-label-outside text-red">
                      Location Name Required *
                    </span>
                  ) : (
                    <span className="floating-label-outside">
                      Location Name *
                    </span>
                  )}

                  {/* {errors["other_value"] && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors["other_value"]}
                    </span>
                  )} */}

                  {/* <span className="floating-label-outside">
                    Location Name *
                  </span> */}
                </div>
              )}

              {/* House no / Flat / Block / Building */}
              <div className="position-relative mt-4">
                <input
                  type="text"
                  name="house_no"
                  className="outside"
                  value={save_address_data.house_no}
                  onChange={(e: any) => {
                    SetSaveAddressData((prevalue: any) => {
                      prevalue.house_no = e.target.value;
                      // validate_save_address_data(prevalue);
                      handleFieldChange(e);
                      return { ...prevalue };
                    });
                  }}
                />
                {errors.house_no ? (
                  <span className="floating-label-outside text-red">
                    House no / Flat / Block / Building Required *
                  </span>
                ) : (
                  <span className="floating-label-outside">
                    House no / Flat / Block / Building *
                  </span>
                )}

                {/* {errors["house_no"] && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors["house_no"]}
                  </span>
                )} */}

                {/* <span className="floating-label-outside">
                  House no / Flat / Block / Building *
                </span> */}
              </div>

              {/* Landmark */}
              <div className="position-relative mt-4">
                <input
                  type="text"
                  className="outside"
                  name="landmark"
                  value={save_address_data.landmark}
                  onChange={(e: any) => {
                    SetSaveAddressData((prevalue: any) => {
                      prevalue.landmark = e.target.value;
                      // validate_save_address_data(prevalue);
                      handleFieldChange(e);
                      return { ...prevalue };
                    });
                  }}
                />

                {errors.landmark ? (
                  <span className="floating-label-outside text-red">
                    Landmark Required *
                  </span>
                ) : (
                  <span className="floating-label-outside">Landmark *</span>
                )}

                {/* {errors["landmark"] && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors["landmark"]}
                  </span>
                )} */}

                {/* <span className="floating-label-outside">Landmark *</span> */}
              </div>

              <div className="hr-text">
                <span>Optional</span>
              </div>

              {/* Contact Name Required * */}
              <div className="position-relative mt-4">
                <input
                  type="text"
                  className="outside"
                  name="name"
                  value={save_address_data.name}
                  onChange={(e: any) => {
                    SetSaveAddressData((prevalue: any) => {
                      prevalue.name = e.target.value;
                      // validate_save_address_data(prevalue);
                      handleFieldChange(e);
                      return { ...prevalue };
                    });
                  }}
                />
                {errors.name ? (
                  <span className="floating-label-outside text-red">
                    Contact Name Required
                  </span>
                ) : (
                  <span className="floating-label-outside">Contact Name</span>
                )}
                {/* {errors["name"] && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors["name"]}
                  </span>
                )} */}
                {/* <span className="floating-label-outside">Contact Name *</span> */}
              </div>

              {/* Mobile Number * */}
              <div className="position-relative mt-4">
                <input
                  type="tel"
                  // maxLength={10}
                  // minLength={10}
                  className="outside"
                  name="mobile"
                  value={save_address_data.mobile}
                  onChange={(e: any) => {
                    SetSaveAddressData((prevalue: any) => {
                      prevalue.mobile = e.target.value;
                      // validate_save_address_data(prevalue);
                      handleFieldChange(e);
                      return { ...prevalue };
                    });
                  }}
                />
                {errors.mobile ? (
                  <span className="floating-label-outside text-red">
                    Invalid Mobile Number
                  </span>
                ) : (
                  <span className="floating-label-outside">Mobile Number</span>
                )}
                {/* {errors["mobile"] && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors["mobile"]}
                  </span>
                )} */}
                {/* <span className="floating-label-outside">Mobile Number *</span> */}
              </div>
            </div>

            <button
              className="primary-button mb-md-2 mb-5"
              onClick={() => {
                handleSubmit();
                // SetConfirmAddress(false);
                // SetAddAddressDialogData({
                //   is_open: false,
                // });
                // if (validate_save_address_data(save_address_data)) {
                //   if (props.from != "edit_address") {
                //     SaveAddress(save_address_data);
                //   } else {
                //     toast.error("Edit on Process");
                //   }
                // }
              }}
            >
              Save Address
            </button>
          </div>
          {/* ) : null} */}
        </Dialog>
      )}

      {/* location confirm */}
      <SwipeableBottomSheet
        open={confirm_location}
        // overlay={true}
        // overflowHeight={0}
        onChange={() => {
          SetConfirmLocation(false);
        }}
      >
        <div className="px-2 py-3 text-center bg-fff sticky-top custom-swipe-shadow">
          <div className="center p-3 ">
            {/* <img src={AppConfig.CDN_Media_url + ""} className="w-30px" /> */}
            <img
              src={
                AppConfig.CDN_Media_url + "fa87db880efda3e5cc5c5d11e350e660.svg"
              }
              className="w-50px"
            />
          </div>
          <div className="loc-head mt-2">
            Are you sure of the selected location?
          </div>
          <div className="py-2 sub-head-text">
            Your selected location seems to be a little far off from the device
            location
          </div>

          <button
            className="primary-button mt-3"
            onClick={() => {
              SetConfirmLocation(false);
            }}
          >
            No, select another location
          </button>

          <button
            className="primary-button bg-fff w-100 text-primary mt-2"
            onClick={() => {
              if (props.from == "get_location_to_save") {
                let send_data = {
                  action: props.from,
                  lat: location_data.lat,
                  lng: location_data.lng,
                  // full_address: place_details?.formatted_address,
                  full_address:
                    place_details?.own_address_data?.formatted_address,
                  // short_address: place_details?.name,
                  short_address: place_details?.own_address_data?.short_address,
                };
                props.onClose(send_data);
              }
              if (props.from == "search") {
                let send_data = {
                  action: props.from,
                  lat: location_data.lat,
                  lng: location_data.lng,
                  // full_address: place_details?.formatted_address,
                  full_address:
                    place_details?.own_address_data?.formatted_address,
                  // short_address: place_details?.name,
                  short_address: place_details?.own_address_data?.short_address,
                };
                props.onClose(send_data);
              } else {
                let send_data = {
                  from: "location_picker",
                  picked_lat_long: location_data,
                  // picked_address: place_details?.formatted_address,
                  picked_address:
                    place_details?.own_address_data?.formatted_address,
                  // short_map_address: place_details?.name,
                  short_map_address:
                    place_details?.own_address_data?.short_address,
                };
                console.log("on save send_data :", send_data);
                set_shipment(send_data);
              }
            }}
          >
            Yes, continue with this location
          </button>
        </div>
      </SwipeableBottomSheet>

      {/* </div> */}
    </>
  );
}
