import React, { useState, useEffect } from "react";

const DataContext = React.createContext();

function DataProvider(props) {
  const [sample_data, SetSampleData] = useState("");
  let html: any = document.querySelector("html"), body: any = document.querySelector("body");
  const [app_data, SetAppData] : any = useState({
    init_called : false,
    sections: {
      sector_name: "",
      sections_type: "",
      sections_id: "",
      sections_slug: "",
    },
    restaurant: {
      type: "",
      id: "",
      slug: "",
      subCategory_id: "",
      subCategory: [],
      brand_name: "",
      location: "",
      name: "",
    },
    products: {
      id: "",
      slug: "",
      variant_slug: "",
      sellers_slug: "",
    },
    backHaves: false,
    reviews_data: {},
    sample_test1: "",
    sample_test2: "",

    //
    selected_section: {},
    selected_vendor: {},
    selected_vendor_outlet: {},
    selected_category: {},
    selected_delivery_address: "",
    delivery_address: [],
    address_type: "",
    checkout_data: [],
    store_details: {},
    user_details: {},
    image_sizes: [],
    image_base_url: "",
    auth: {},
    shipment_details: {},
    application: { platform: "web" },
    country_options: [],
    specifications: [],
    section_cart_count : {},
    all_sections: [],
    new_cart_count: 0,
    new_notifications: 0,
    location_loading : false,
    location_called : false,
    tutorials : {},
    scroll_locked: 0,
    header_content:"",
    bg_white : 0,
    bg_grey : 0,
    topMarginZero : 0,
    searched_prod_id : 0,
    triggerChangeDeliveryAddress : 0,
    triggerChangeBrandWishlist : 0,
    triggerVendorShareWindow : 0,
    triggerVendorDirection : 0,
    triggerCloseHeaderAddressModal : 0,
    vendorpageHeader: false,
    pageScrolledDown: false,
    vendorpageHeaderTitle: '',
    have_mobile_app_key : false,
    mobile_app_key_value : "",
    location_called_in_header : false,
    view_cart_from : "",
    default_payment_type : "",
    checkout_order_ids : [],
    is_location_on : false,
    location_data : {},
  });
  const [selected_section,SetSelectedScetion] = useState({})
  useEffect(() => {
    console.log("DataProvider On mount :");
  }, []);

  useEffect(() => {
    if (props.selected_language != {} && props.language_data != {}) {
      console.log("DataProvider On props change :");
      SetAppData((prevValue) => {
        prevValue.selected_language = props.selected_language;
        prevValue.language_data = props.language_data;
        prevValue.language_list = props.language_list;
        return { ...prevValue };
      });
    }
    if (props.application) {
      SetAppData((prevValue) => {
        prevValue.application = props.application;
        return { ...prevValue };
      });
    }
  }, [props]);

  useEffect(() => {
    console.log('app_data.scroll_locked', app_data.scroll_locked);
    
    if(app_data.scroll_locked > 0){
      html.style.overflowY = "hidden";
      html.style.overscrollBehaviorY = "none";
      body.style.overflowY = "hidden";
      body.style.overscrollBehaviorY = "none";
    } else {
      html.style.overflowY = "";
      html.style.overscrollBehaviorY = "";
      body.style.overflowY = "";
      body.style.overscrollBehaviorY = "";
    }
  }, [app_data?.scroll_locked]);

  useEffect(() => {    
    if(app_data.bg_white > 0){
      body.style.backgroundColor = "#ffffff";
    } else if(app_data.bg_grey > 0) {
      body.style.backgroundColor = "#f6f9ff";
    } else {
      body.style.backgroundColor = "#ffffff";
    }
  }, [app_data?.bg_white, app_data?.bg_grey]);

  function t(value : any) {
    if (app_data.language_data.hasOwnProperty(value)) {
      return app_data.language_data[value];
    } else {
      return value;
    }
  }

  function is_data_available(get_data:any){
    let return_data = false;
    if(app_data.hasOwnProperty(get_data.key)){
      console.log("type of :",typeof(app_data[get_data.key]));
      if(typeof(app_data[get_data.key]) == "object"){
        if(Array.isArray(app_data[get_data.key])){
          if(app_data[get_data.key].length > 0){
            return_data = true;
          }else{
            return_data = false;
          }
        }else{
          if(Object.keys(app_data[get_data.key]).length > 0){
            return_data = true;
          }else{
            return_data = false;
          }
        }
      }else{
        return_data = true;
      }
      // if(typeof(app_data[get_data.key]) == "string"){
      // }
      // if(typeof(app_data[get_data.key]) == "number"){
      // }
      // if(typeof(app_data[get_data.key]) == "boolean"){
      // }
    }else{
      return_data = false;
    }
    return return_data;
  }

  return (
    <DataContext.Provider
      value={{
        app_data,
        SetAppData,
        selected_section,
        SetSelectedScetion,
        t,
        is_data_available,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
export { DataProvider };

// export const DataProvider = DataContext.Provider;
export const DataConsumer = DataContext.Consumer;

export default DataContext;
